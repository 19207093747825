import type { OnInit } from '@angular/core';
import {
  Component,
  ViewEncapsulation,
  Input,
  HostBinding,
} from '@angular/core';
import { MenuService } from '../../../core/bootstrap/menu.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'page-header',
  host: {
    class: 'matero-page-header',
  },
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() nav: string[] = [];
  @Input() showBreadCrumb = true;
  message = 'Home';

  constructor(
    private router: Router,
    private menuService: MenuService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.nav = Array.isArray(this.nav) ? this.nav : [];

    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }

    this.title = this.title || this.nav[this.nav.length - 1];

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.genBreadcrumb();
    });
  }

  genBreadcrumb() {
    this.translate
      .get('menu.home')
      .subscribe((text: string) => (this.message = text));

    let states = this.router.url.slice(1).split('/');
    if (
      this.router.url.includes('ui/signbox') === false &&
      states[0] === 'ui'
    ) {
      states = this.router.url.slice(4).split('/');
      states[0] = 'ui/' + states[0];
    }
    this.nav = this.menuService.getLevel(states);
    this.nav.unshift(this.message);
  }
}
