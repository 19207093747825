<ul class="matero-sidemenu level-0" navAccordion>
  <li *ngFor="let menuItem of menus | async" [group]="menuItem.route" class="matero-sidemenu-item"
      navAccordionLink
      routerLinkActive="active">
    <a *ngIf="menuItem.type === 'link'" [matRippleDisabled]="!ripple"
       [routerLink]="buildRoute([menuItem.route])"
       class="matero-sidemenu-link" matRipple>
      <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span *ngIf="menuItem.label" class="menu-label bg-{{menuItem.label.color}}">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span *ngIf="menuItem.badge" class="menu-badge bg-{{menuItem.badge.color}}">
        {{menuItem.badge.value}}
      </span>
    </a>
    <a *ngIf="menuItem.type === 'extLink'" [href]="menuItem.route" [matRippleDisabled]="!ripple"
       class="matero-sidemenu-link" matRipple>
      <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span *ngIf="menuItem.label" class="menu-label bg-{{menuItem.label.color}}">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span *ngIf="menuItem.badge" class="menu-badge bg-{{menuItem.badge.color}}">
        {{menuItem.badge.value}}
      </span>
      <mat-icon class="menu-caret">launch</mat-icon>
    </a>
    <a *ngIf="menuItem.type === 'extTabLink'" [href]="menuItem.route" [matRippleDisabled]="!ripple"
       class="matero-sidemenu-link"
       matRipple target="_blank">
      <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span *ngIf="menuItem.label" class="menu-label bg-{{menuItem.label.color}}">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span *ngIf="menuItem.badge" class="menu-badge bg-{{menuItem.badge.color}}">
        {{menuItem.badge.value}}
      </span>
      <mat-icon class="menu-caret">launch</mat-icon>
    </a>
    <!-- level 1 -->
    <a *ngIf="menuItem.type === 'sub'" [matRippleDisabled]="!ripple" class="matero-sidemenu-toggle"
       href="javascript:void(0);"
       matRipple navAccordionToggle>
      <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span *ngIf="menuItem.label" class="menu-label bg-{{menuItem.label.color}}">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span *ngIf="menuItem.badge" class="menu-badge bg-{{menuItem.badge.color}}">
        {{menuItem.badge.value}}
      </span>
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </a>
    <ul *ngIf="menuItem.type === 'sub'" class="matero-sidemenu submenu level-1" navAccordion>
      <li *ngFor="let childLvl1 of menuItem.children" [group]="childLvl1.route" class="matero-sidemenu-item"
          navAccordionLink
          routerLinkActive="active">
        <a *ngIf="childLvl1.type === 'link'"
           [matRippleDisabled]="!ripple"
           [routerLink]="buildRoute([menuItem.route, childLvl1.route])"
           class="matero-sidemenu-link" matRipple>
          <span class="menu-name">{{childLvl1.name | translate}}</span>
        </a>
        <a *ngIf="childLvl1.type === 'extLink'" [href]="childLvl1.route"
           [matRippleDisabled]="!ripple"
           class="matero-sidemenu-link" matRipple>
          <span class="menu-name">{{childLvl1.name | translate}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">launch</mat-icon>
        </a>
        <a *ngIf="childLvl1.type === 'extTabLink'" [href]="childLvl1.route" [matRippleDisabled]="!ripple"
           class="matero-sidemenu-link"
           matRipple target="_blank">
          <span class="menu-name">{{childLvl1.name | translate}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">launch</mat-icon>
        </a>
        <!-- level 2 -->
        <a *ngIf="childLvl1.type === 'sub'" [matRippleDisabled]="!ripple" class="matero-sidemenu-toggle"
           href="javascript:void(0);"
           matRipple navAccordionToggle>
          <span class="menu-name">{{childLvl1.name | translate}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
        </a>
        <ul *ngIf="childLvl1.type === 'sub'" class="matero-sidemenu submenu level-2">
          <li *ngFor="let childLvl2 of childLvl1.children" class="matero-sidemenu-item"
              routerLinkActive="active">
            <a *ngIf="childLvl2.type === 'link'"
               [matRippleDisabled]="!ripple"
               [routerLink]="buildRoute([menuItem.route, childLvl1.route, childLvl2.route])"
               class="matero-sidemenu-link" matRipple>
              <span class="menu-name">{{childLvl2.name | translate}}</span>
            </a>
            <a *ngIf="childLvl2.type === 'extLink'" [href]="childLvl2.route"
               [matRippleDisabled]="!ripple"
               class="matero-sidemenu-link" matRipple>
              <span class="menu-name">{{childLvl2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">launch</mat-icon>
            </a>
            <a *ngIf="childLvl2.type === 'extTabLink'" [href]="childLvl2.route" [matRippleDisabled]="!ripple"
               class="matero-sidemenu-link"
               matRipple target="_blank">
              <span class="menu-name">{{childLvl2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">launch</mat-icon>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
