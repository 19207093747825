import type { OnInit } from '@angular/core';
import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MenuService } from '../../../core/bootstrap/menu.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
  @Input() nav: string[] = [];
  message = 'Home';

  constructor(
    private router: Router,
    private menuService: MenuService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.nav = Array.isArray(this.nav) ? this.nav : [];

    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.genBreadcrumb();
    });
  }

  trackByNavlink(index: number, navlink: string): string {
    return navlink;
  }

  genBreadcrumb() {
    this.translate
      .get('menu.home')
      .subscribe((text: string) => (this.message = text));

    let states = this.router.url.slice(1).split('/');
    if (
      this.router.url.includes('ui/signbox') === false &&
      states[0] === 'ui'
    ) {
      states = this.router.url.slice(4).split('/');
      states[0] = 'ui/' + states[0];
    }
    this.nav = this.menuService.getLevel(states);
    this.nav.unshift(this.message);
  }
}
