import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@esign/material';

import { SharedModule } from '../shared/shared.module';

import { UserLayoutComponent } from './user-layout/user-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { WizardLayoutComponent } from './wizard-layout/wizard-layout.component';
import { GuestLayoutComponent } from './guest-layout/guest-layout.component';

import { SidebarComponent } from './sidebar/sidebar.component';
import { UserPanelComponent } from './sidebar/user-panel.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { AccordionAnchorDirective } from './sidemenu/accordionanchor.directive';
import { AccordionDirective } from './sidemenu/accordion.directive';
import { AccordionLinkDirective } from './sidemenu/accordionlink.directive';
import { SidebarNoticeComponent } from './sidebar-notice/sidebar-notice.component';

import { TopmenuComponent } from './topmenu/topmenu.component';

import { HeaderComponent } from './header/header.component';
import { HeaderUserComponent } from './header/header.user.component';
import { HeaderGuestComponent } from './header/header.guest.component';
import { BrandingComponent } from './header/branding.component';
import { NotificationComponent } from './header/notification.component';
import { TranslateComponent } from './header/translate.component';
import { UserComponent } from './header/user.component';
import { SignboxUserComponent } from './header/signbox-user.component';
import { AnonymousComponent } from './header/anonymous.component';
import { UserProfileLayoutComponent } from './profile/user-profile-layout/user-profile-layout.component';
import { ProfileDataLayoutComponent } from './profile/profile-data-layout/profile-data-layout.component';
import { PageHeaderLayoutComponent } from './page-header-layout/page-header-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from './auth-redirect/redirect.component';
import { BrandingSidebarComponent } from './header/branding-sidebar.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressRouter } from 'ngx-progressbar/router';

@NgModule({
  declarations: [
    UserLayoutComponent,
    GuestLayoutComponent,
    UserProfileLayoutComponent,
    ProfileDataLayoutComponent,
    PageHeaderLayoutComponent,
    AuthLayoutComponent,
    WizardLayoutComponent,
    SidebarComponent,
    UserPanelComponent,
    SidemenuComponent,
    AccordionAnchorDirective,
    AccordionDirective,
    AccordionLinkDirective,
    SidebarNoticeComponent,
    TopmenuComponent,
    HeaderComponent,
    HeaderUserComponent,
    HeaderGuestComponent,
    BrandingComponent,
    BrandingSidebarComponent,
    NotificationComponent,
    TranslateComponent,
    UserComponent,
    SignboxUserComponent,
    AnonymousComponent,
    RedirectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgProgressbar,
    NgProgressRouter,
  ],
})
export class ThemeModule {}
