import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { PageTitleService } from '@esign/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-401',
  template: ` <error-code code="401" [title]="title" [message]="message"></error-code> `,
})
export class Error401Component implements OnInit {
  title: string;
  message: string;

  constructor(private pageTitleService: PageTitleService, private translate: TranslateService) {
    this.translations();
  }

  ngOnInit() {
    this.pageTitleService.title = '401';
  }

  translations() {
    this.translate.stream('error-page.401.title').subscribe((text: string) => {
      this.title = text;
    });

    this.translate.stream('error-page.401.message').subscribe((text: string) => {
      this.message = text;
    });
  }
}
