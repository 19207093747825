import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  OAuthService,
  AuthConfig,
  OAuthErrorEvent,
  OAuthSuccessEvent,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Console } from 'console';
import { timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserLoginService {
  public claims: any;
  currentUrl: any;

  isDownloadUserRole: boolean = false;
  userRoles: Array<String> = new Array();
  constructor(
    private router: Router,
    public oauthService: OAuthService,
    private oauthStorage: OAuthStorage,
    public translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.configure();
  }

  authConfig: AuthConfig = {
    issuer: window.location.origin + '/auth/realms/esign',
    redirectUri: window.location.origin + '/callback',
    clientId: 'esign-frontend',
    scope: 'openid',
    responseType: 'code',
    requireHttps: false,
    disableAtHashCheck: false,
    clearHashAfterLogin: false,
    strictDiscoveryDocumentValidation: false,
    postLogoutRedirectUri: window.location.origin + '/home&client_id=esign-frontend',
  };

  isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public login(autoRedirectIdp?: boolean) {
    if (
      autoRedirectIdp != null &&
      autoRedirectIdp != undefined &&
      autoRedirectIdp
    ) {
      this.oauthService.initLoginFlow('', {
        kc_idp_hint: 'external_system',
      });
    } else {
      this.oauthService.initLoginFlow();
    }
  }

  public logout() {
    this.oauthService.logOut();
  }

  refreshToken() {
    return this.oauthService.refreshToken().catch(() => {
      window.location.reload();
    });
  }

  private configure() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.timeoutFactor = 0.75;

    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  public get name() {
    let claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['preferred_username'];
  }

  public get email() {
    let claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['email'];
  }

  public get userId() {
    let claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['sub'];
  }

  public get token() {
    let token = this.oauthService.getAccessToken();
    return token;
  }

  public redirectOnCallback(): void {
    this.oauthService.events.subscribe((event) => {
      if (event.type === 'token_expires') {
        this.currentUrl = this.router.url;
      }

      if (event instanceof OAuthErrorEvent) {
        console.error(event);

        let message;
        this.translate
          .get('login.error.message')
          .subscribe((text: string) => (message = text));

        this.snackBar.open(message, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });

        this.router.navigateByUrl('/ui');
      } else if (event instanceof OAuthSuccessEvent) {
        if (event.type === 'token_received') {
          this.router.navigateByUrl('/ui');
        } else if (event.type === 'token_refreshed') {
          if (
            this.currentUrl === undefined ||
            this.currentUrl === null ||
            this.currentUrl === ''
          ) {
            this.router.navigateByUrl('/ui');
          } else {
            this.router.navigateByUrl(this.currentUrl);
          }
        }
      }
    });
  }

  async downloadUserRole(): Promise<boolean> {
    if (this.isDownloadUserRole) {
      return;
    }

    if (this.oauthStorage.getItem('id_token_claims_obj') === null) {
      timer(500)
        .toPromise()
        .then(() => {
          this.isDownloadUserRole = false;
          this.downloadUserRole();
        });
    } else {
      let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));
      let roles: Array<string> = claims.role;
      return new Promise(async (resolve, reject) => {
        this.userRoles = roles;
        this.isDownloadUserRole = true;
        resolve(true);
      });
    }
  }

  public async hasRole(role: string) {
    var res = await this.downloadUserRole();
    if (this.userRoles.includes('super_admin'.valueOf()).valueOf()) {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }

    for (var i = 0, len = this.userRoles.length; i < len; i++) {
      if (this.userRoles[i].valueOf() === role) {
        return new Promise((resolve, reject) => {
          resolve(true);
        });
      }
    }
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  }
}
