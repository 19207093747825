import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CertificateDetailsI } from '../../models/certificate-details';

@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  constructor(private httpClient: HttpClient) {}

  activeInternalCertificate() {
    return this.httpClient
      .get('/dss/api/v1/sign/active/internal')
      .pipe(catchError(this.errorHandler));
  }

  activeInnerCertificateSign() {
    return this.httpClient
      .get('/dss/api/v1/sign/active/inner-sign')
      .pipe(catchError(this.errorHandler));
  }
  
  detailsCertificate(certificateId: string): Observable<CertificateDetailsI|any> {
    return this.httpClient
      .get<CertificateDetailsI>('/crt/db/api/v1/certificate/details?certificateId=' + certificateId)
      .pipe(catchError(this.errorHandler));
  }

  getUserCertificate(): Observable<CertificateDetailsI> {
    return this.httpClient
      .get<CertificateDetailsI>('/crt/db/api/v1/user/certificate/get')
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
