import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuardService {
  constructor(private httpClient: HttpClient) {}

  activeInternalCertificate() {
    return this.httpClient
      .get('/dss/api/v1/sign/active/internal')
      .pipe(catchError(this.errorHandler));
  }

  activeInnerSign() {
    return this.httpClient
      .get('/dss/api/v1/sign/active/inner-sign')
      .pipe(catchError(this.errorHandler));
  }

  activeSealInnerSign() {
    return this.httpClient
      .get('/dss/api/v1/sign/active/seal-inner-sign')
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
