import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgViewerComponent } from './components/svg-viewer/svg-viewer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@esign/material';
import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [SvgViewerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MaterialModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://esign.drp.dev'],
        sendAccessToken: true,
      },
    }),
  ],
  exports: [SvgViewerComponent],
  providers: []
})
export class SharedModule {}
