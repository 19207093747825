<footer class="esign-footer">

  <div class="esign-footer-list">

    <div class="esign-footer-logo">
      <div class="footer-logo">
        <img alt="logo" class="esign-footer-app-logo"
             src="./assets/images/logo.svg">

        <span><a href="ui/">{{"systemName" | translate}}</a></span>
      </div>
    </div>

    <div class="esign-footer-version">
      <span class="version">{{"guest.footer.current-version" | translate}}: {{version}}</span>
    </div>

    <div class="esign-footer-copyright">
      <span>©{{year}} OPTeam S.A.</span>
    </div>

  </div>

</footer>
