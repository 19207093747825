import { NgModule } from '@angular/core';
import { MaterialModule } from '@esign/material';
import { SharedModule as MateroSharedModule, RoutesModule } from '@ng-matero/common';
import { RoutesRoutingModule } from './routes-routing.module';

const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [
    MaterialModule,
    MateroSharedModule,
    RoutesModule,
    RoutesRoutingModule
  ],
  declarations: [...COMPONENTS_DYNAMIC]
})
export class RoutesAppModule {}
