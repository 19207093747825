import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@esign/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { FlexModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    PageHeaderComponent,
    ErrorCodeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FlexModule,
    NgSelectModule,
    FormlyModule,
    FormlyMaterialModule,
    ToastrModule,
    TranslateModule
  ],
  exports: [
    BreadcrumbComponent,
    PageHeaderComponent,
    ErrorCodeComponent
  ]
})
export class SharedModule {}
