import { AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import type { HttpErrorResponse } from '@angular/common/http';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

import type { MatButton } from '@esign/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AppGlobalService,
  DEFAULT_USER_PHOTO,
  PageTitleService,
  UserPhotoService,
} from '@esign/common';
import { ProfilePhotoService } from '../../../services/profile-photo/profile-photo.service';

@Component({
  selector: 'lib-esign-common-avatar-profile',
  templateUrl: './avatar-profile.component.html',
  styleUrls: ['./avatar-profile.component.css'],
})
export class AvatarProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('deletePhotoBtn') deletePhotoBtn: MatButton;

  imageForm: FormGroup;

  showFormatError = false;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    public appGlobal: AppGlobalService,
    private photoService: ProfilePhotoService,
    private userPhotoService: UserPhotoService,
    private snackBar: MatSnackBar,
    private pageTitleService: PageTitleService
  ) {
    this.pageTitleService.title = this.setPageTitle();
    this.initImageForm();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.pageTitleService.title = this.setPageTitle();
    });
  }

  ngAfterViewInit(): void {

  }

  setPageTitle(): string {
    let title = '';
    this.translate.get('page-title.profile').subscribe((text: string) => (title = text));
    return title;
  }

  initImageForm() {
    this.imageForm = this.fb.group({
      image: ['', [Validators.required]],
    });
  }

  getImagePath() {
    return this.appGlobal.imagePath;
  }

  isUploadPhoto(): boolean {
    return !(this.appGlobal.photo === null || this.appGlobal.photo === undefined);
  }

  selectFile(event) {
    if (
      event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpg' ||
      event.target.files[0].type === 'image/jpeg'
    ) {
      this.imageForm.controls.image.setValue(event.target.files);
      this.showFormatError = false;
    } else {
      this.showFormatError = true;
    }
  }

  cleanForm() {
    this.imageForm.reset();
  }

  uploadImage() {
    if (this.imageForm.valid) {
      var formData = new FormData();
      formData.append('photo', this.imageForm.controls.image.value[0]);

      this.photoService.uploadPhoto(formData).subscribe(
        () => {
          this.isUploadPhoto();
          this.cleanForm();

          this.translate.get('profile.avatar.popoup.success-change').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-success'], });
          });
          this.showFormatError = false;

          this.appGlobal.isDownloadedPhoto = false;
          this.userPhotoService.load();
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.avatar.popoup.failed-change').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
        }
      );
    } else {
      this.imageForm.markAllAsTouched();
    }
  }

  deletePhoto() {
    this.photoService.deletePhoto().subscribe(
      () => {
        this.isUploadPhoto();
        this.translate
          .get('profile.avatar.popoup.succes-delete-photo')
          .subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-success'], });
          });
        this.showFormatError = false;

        this.appGlobal.isDownloadedPhoto = false;
        this.deletePhotoBtn.disabled = true;
        this.appGlobal.userPhoto = DEFAULT_USER_PHOTO;
      },
      (err: HttpErrorResponse) => {
        this.translate
          .get('profile.avatar.popoup.failed-delete-photo')
          .subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'] });
          });
      }
    );
  }
}
