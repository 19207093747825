import { Injectable } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserSignVisualizationService {
  constructor(private http: HttpClient) { }

  configImageForSeal(){
    return this.http.get('/dss/api/v1/settings/visualization/config-for-seal').pipe(catchError(this.errorHandler));
  }

  uploadPhoto(data) {
    return this.http.post('/dss/api/v1/user/profile/visualization/upload', data).pipe(catchError(this.errorHandler));
  }

  deleteDefaultPhoto() {
    return this.http.delete('/dss/api/v1/user/profile/visualization/delete/default').pipe(catchError(this.errorHandler));
  }

  deletePhoto(signType) {
    return this.http.delete('/dss/api/v1/user/profile/visualization/delete', {
      params: {
        'sign-type': signType
      }
    }).pipe(catchError(this.errorHandler));
  }

  downloadUserDefaultPhoto() {
    return this.http.get('/dss/api/v1/user/profile/visualization/get/default', { responseType: 'blob' }).pipe(catchError(this.errorHandler));
  }

  downloadDefaultPhoto() {
    return this.http.get('/dss/api/v1/settings/visualization/get/default', { responseType: 'blob' }).pipe(catchError(this.errorHandler));
  }

  downloadSignTypePhoto(signType) {
    return this.http.get('/dss/api/v1/user/profile/visualization/get', {
      responseType: 'blob',
      params: {
        'sign-type': signType
      }
    }).pipe(catchError(this.errorHandler));
  }

  isSettingsDefaultPhoto() {
    return this.http.get('/dss/api/v1/user/profile/visualization/is-default').pipe(catchError(this.errorHandler));
  }

  isSettingsPhoto(signType) {
    return this.http.get('/dss/api/v1/user/profile/visualization/is-photo',{
      params: {
      'sign-type': signType
    }}).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
