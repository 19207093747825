<div class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">
  <img [src]="getImagePath()" alt="avatar" class="matero-user-panel-avatar" width="64" />
  <h4 class="matero-user-panel-name">{{ this.appGlobal.userDisplayName }}</h4>
  <h5 class="matero-user-panel-email">{{ this.appGlobal.userEMail }}</h5>
  <div class="matero-user-panel-icons">
    <a mat-icon-button routerLink="/ui/profile/data">
      <mat-icon>account_circle</mat-icon>
    </a>
    <!-- <a mat-icon-button routerLink="/profile/settings">
      <mat-icon>settings</mat-icon>
    </a> -->
    <a (click)="logout()" mat-icon-button>
      <mat-icon>exit_to_app</mat-icon>
    </a>
  </div>
</div>