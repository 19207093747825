<mat-toolbar class="matero-toolbar">
  <button (click)="toggleSidenav.emit()" *ngIf="showToggle" class="matero-toolbar-button"
          mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <app-branding *ngIf="showBranding"></app-branding>
  <span fxFlex></span>

  <button (click)="toggleFullscreen()" class="matero-toolbar-button" fxHide.lt-sm mat-icon-button>
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-translate></app-translate>

  <app-user></app-user>

</mat-toolbar>
