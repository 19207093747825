import type { OnInit } from '@angular/core';
import { Component, ChangeDetectorRef } from '@angular/core';
import type { UserDetails, UserPhoto } from '@esign/common';
import { AppGlobalService, UserLoginService } from '@esign/common';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
})
export class UserPanelComponent implements OnInit {

  constructor(
    public appGlobal: AppGlobalService,
    private changeDetectorRef: ChangeDetectorRef,
    private userLoginService: UserLoginService
  ) {

  }

  ngOnInit() {
    this.appGlobal.userDetailsChanged.subscribe((newVal: UserDetails) => {
     this.changeDetectorRef.detectChanges();
    });
    this.appGlobal.userPhotoChanged.subscribe((newVal: UserPhoto) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  logout() {
    this.userLoginService.logout();
  }

  getImagePath() {
    return this.appGlobal.imagePath;
  }
}
