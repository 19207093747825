import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@esign/material';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../core/bootstrap/settings.service';

@Component({
  selector: 'app-translate',
  template: `
    <button
      mat-icon-button
      class="matero-toolbar-button"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>translate</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let lang of langs | keyvalue"
        (click)="useLanguage(lang.key)"
      >
        <mat-icon svgIcon="flag-{{ lang.key }}" class="flag-icon"></mat-icon>
        <span>{{ lang.value }}</span>
      </button>
    </mat-menu>
  `,
  styles: [
    `
      .flag-icon {
        margin-right: 8px !important;
        margin-bottom: 4px;
      }
    `,
  ],
})
export class TranslateComponent {
  langs = {
    'pl-PL': 'Polski',
    'en-US': 'English',
  };

  constructor(public translate: TranslateService, private settings: SettingsService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('flag-pl-PL', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/flags/pl.svg'));
    iconRegistry.addSvgIcon('flag-en-US', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/flags/gb.svg'));
    translate.addLangs(['pl-PL', 'en-US']);
  }

  public useLanguage(language: string) {
    this.translate.use(language);
    this.settings.setLanguage(language);
    sessionStorage.removeItem('lang');
    sessionStorage.setItem('lang', language);
  }
}
