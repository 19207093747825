export interface UserDetailsI {
  displayName: string;
  name: string;
  email: string;
  temporary: boolean;
}

export class UserDetails implements UserDetailsI {
  displayName: string;
  name: string;
  email: string;
  temporary: boolean;

  setValue(value: UserDetailsI) {
    this.displayName = value.displayName;
    this.email = value.email;
    this.temporary = value.temporary;

    if (value.name === '00000000-0000-0000-0000-000000000000') {
      this.name = '';
    } else {
      this.name = value.name;
    }
  }

  getValue(): UserDetailsI {
    return {
      displayName: this.displayName,
      name: this.name,
      email: this.email,
      temporary: this.temporary,
    };
  }

  userDisplayName() {
    return this.displayName != null && this.displayName.length > 0 ? this.displayName : this.name;
  }
}

export const EMPTY_USER_DETAILS: UserDetailsI = {
  name: '',
  email: '',
  displayName: '',
  temporary: false,
};
