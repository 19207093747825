<mat-card>
  <div class="page-header">
    {{ "profile.sign-visualization.title" | translate }}
  </div>
  <hr />
  <div class="page-description">
    {{ "profile.sign-visualization.description" | translate }}
  </div>
  <br /><br />

  <div *ngIf="configImageForSeal">
    <mat-tab-group
      class="mat-group"
      (selectedTabChange)="signTypeChange($event)"
    >
      <mat-tab label="{{ 'profile.sign-visualization.default' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.default" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-default-photo" | translate
                }}
              </div>
              <br />
              <img
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadDefaultPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deleteDefaultPhoto()"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-default-photo-btn"
                      | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadDefaultPhoto">
                {{ "profile.sign-visualization.not-can-delete" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{
                  "profile.sign-visualization.upload-default-photo" | translate
                }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadDefaultPhoto()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'profile.sign-visualization.qualified' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.qualified" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-qualified-photo" | translate
                }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('QUALIFIED')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('QUALIFIED')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'profile.sign-visualization.seal' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{ "profile.sign-visualization.seal" | translate }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{ "profile.sign-visualization.info-seal-photo" | translate }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('SEAL')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('SEAL')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab
        label="{{ 'profile.sign-visualization.seal-trusted' | translate }}"
      >
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.seal-trusted" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-seal-trusted-photo"
                    | translate
                }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('SEAL_TRUSTED')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('SEAL_TRUSTED')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'profile.sign-visualization.inner' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{ "profile.sign-visualization.inner" | translate }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{ "profile.sign-visualization.info-inner-photo" | translate }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('INNER')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('INNER')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab
        label="{{ 'profile.sign-visualization.seal-inner' | translate }}"
      >
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.seal-inner" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-seal-inner-photo" | translate
                }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('SEAL_INNER')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('SEAL_INNER')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab
        label="{{
          'profile.sign-visualization.seal-inner-trusted' | translate
        }}"
      >
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.seal-inner-trusted" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-trusted-seal-inner-photo"
                    | translate
                }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('SEAL_INNER_TRUSTED')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('SEAL_INNER_TRUSTED')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="!configImageForSeal">
    <mat-tab-group
      class="mat-group"
      (selectedTabChange)="signTypeChange($event)"
    >
      <mat-tab label="{{ 'profile.sign-visualization.default' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.default" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-default-photo" | translate
                }}
              </div>
              <br />
              <img
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadDefaultPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deleteDefaultPhoto()"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-default-photo-btn"
                      | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadDefaultPhoto">
                {{ "profile.sign-visualization.not-can-delete" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{
                  "profile.sign-visualization.upload-default-photo" | translate
                }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadDefaultPhoto()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'profile.sign-visualization.qualified' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{
                "profile.sign-visualization.qualified" | translate
              }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{
                  "profile.sign-visualization.info-qualified-photo" | translate
                }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('QUALIFIED')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('QUALIFIED')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>

      <mat-tab label="{{ 'profile.sign-visualization.inner' | translate }}">
        <div class="m-16">
          <mat-card-content>
            <div class="sign-title">
              <span>{{ "profile.sign-visualization.inner" | translate }}</span>
            </div>
            <hr />
            <div class="form-photo">
              <div class="info-sign">
                {{ "profile.sign-visualization.info-inner-photo" | translate }}
              </div>
              <br />
              <img
                *ngIf="isUploadPhoto"
                [src]="photoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <img
                *ngIf="!isUploadPhoto"
                [src]="defaultPhotoImagePath"
                alt=""
                class="m-b-8 img-current"
              />
              <div *ngIf="isUploadPhoto" class="delete-photo-btn">
                <button
                  #deletePhotoBtn
                  (click)="deletePhoto('INNER')"
                  class="m-t-8"
                  class="btn-form"
                  color="primary"
                  mat-raised-button
                >
                  {{
                    "profile.sign-visualization.delete-photo-btn" | translate
                  }}
                </button>
              </div>
              <div *ngIf="!isUploadPhoto">
                {{ "profile.sign-visualization.use-default-photo" | translate }}
              </div>

              <hr class="horizontal-line" />

              <div class="info-header">
                {{ "profile.sign-visualization.upload-photo" | translate }}
              </div>

              <div class="form">
                <form [formGroup]="photoForm" class="matero-form-horizontal">
                  <mat-form-field class="field-form">
                    <mat-label
                      *ngIf="photoForm.controls.photo.value; else newFile"
                      >{{ photoForm.controls.photo.value[0].name }}
                    </mat-label>
                    <ng-template #newFile>
                      <mat-label
                        >{{
                          "profile.sign-visualization.choose-file" | translate
                        }}
                      </mat-label>
                    </ng-template>
                    <input disabled matInput />
                    <button
                      (click)="fileInput.click()"
                      mat-icon-button
                      matSuffix
                    >
                      <mat-icon class="attach-icon">attach_file</mat-icon>
                    </button>
                    <input
                      #fileInput
                      (change)="selectFile($event)"
                      (click)="fileInput.value = null"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      id="file"
                      type="file"
                    />
                  </mat-form-field>

                  <div
                    *ngIf="showFormatError"
                    class="help-block error small"
                    id="error-in-upload-photo"
                  >
                    {{ "profile.sign-visualization.form-error" | translate }}
                  </div>
                </form>

                <div class="buttons-form">
                  <button
                    (click)="cleanForm()"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-clear-form" | translate
                    }}
                  </button>

                  <button
                    (click)="uploadPhoto('INNER')"
                    [disabled]="photoForm.invalid"
                    class="m-t-8"
                    class="btn-form"
                    color="primary"
                    mat-raised-button
                  >
                    {{
                      "profile.sign-visualization.btn-save-photo" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>
