import type { OnInit } from '@angular/core';
import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { PageTitleService } from '@esign/common';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-ng-matero-common-profile-data-layout',
  templateUrl: './profile-data-layout.component.html',
  styleUrls: ['./profile-data-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProfileDataLayoutComponent implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private pageTitleService: PageTitleService
  ) {
    this.pageTitleService.title = this.setPageTitle();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.pageTitleService.title = this.setPageTitle();
    });
  }

  setPageTitle(): string {
    let title = '';
    this.translate.get('page-title.profile').subscribe((text: string) => (title = text));
    return title;
  }
}
