import type { OnInit } from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

import { PageTitleService } from '@esign/common';
import { MatSnackBar } from '@esign/material';
import { UserOtpService } from '../../../services/user-otp/user-otp.service';
import { CredentialI, ActiveCredential } from '../../../models/user-credentials';
import { HttpErrorResponse } from '@angular/common/http';
import { AddOtpComponent } from './add-otp/add-otp.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'lib-esign-user-otp',
  templateUrl: './user-otp.component.html',
  styleUrls: ['./user-otp.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserOtpComponent implements OnInit {
  userCredentials: Array<CredentialI> = [];
  activeSmsOtp: boolean = false;
  phoneNumber: string = null;
  activeEmailOtp: boolean = false;
  userEmail: String = null;

  activeDevice: boolean = true;

  preferredDevice: ActiveCredential = null;
  activeCredentials: Array<ActiveCredential> = [];

  preferredDeviceForms: FormGroup;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userOtpService: UserOtpService,
    private formBuilder: FormBuilder,
    private oauthStorage: OAuthStorage
  ) {
    this.pageTitleService.title = this.setPageTitle();

    this.buildForm();
  }

  buildForm() {
    this.preferredDeviceForms = this.formBuilder.group({
      device: ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.getPreferredDevice();

    let res = await this.initData();
    if (res) {
      this.createActiveCredential();
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.pageTitleService.title = this.setPageTitle();
    });
  }

  async initData(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let res = await this.initOtpData();
      let res1 = await this.enableSmsOtp();
      let res2 = await this.enableEmailOtp();
      resolve(true);
    });
  }

  async initOtpData(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.userOtpService.getUserOtpCredentials().subscribe(
        (res: string) => {
          let json = JSON.parse(res);
          if (json.length > 0) {
            this.userCredentials = json[0].userCredentialMetadatas;
            this.changeDetectorRef.detectChanges();
            resolve(true);
          }
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.otp.credentials.download-error').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
          resolve(true);
        }
      );
    });
  }

  deleteOtpCode(id: string) {
    this.userOtpService.deleteUserOtp(id).subscribe(
      (res) => {
        this.initOtpData();
        this.translate.get('profile.otp.credentials.delete-success').subscribe((text: string) => {
          this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-success'], });
        });
      },
      (err: HttpErrorResponse) => {
        this.translate.get('profile.otp.credentials.delete-error').subscribe((text: string) => {
          this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
        });
      }
    );
  }

  addOtpCode() {
    this.userOtpService.addOtp().subscribe(
      (res) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
          otpBarcode: res,
        };

        let dialogRef = this.dialog.open(AddOtpComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
          this.initOtpData();
        });
      },
      (err: HttpErrorResponse) => {
        this.translate.get('profile.otp.credentials.add-error').subscribe((text: string) => {
          this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
        });
      }
    );
  }

  async enableSmsOtp(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.userOtpService.enableSmsOtp().subscribe(
        async (res: boolean) => {
          this.activeSmsOtp = res;
          this.changeDetectorRef.detectChanges();
          if (res) {
            let res = await this.getPhoneNumber();
            resolve(true);
          } else {
            resolve(true);
          }
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.otp.credentials.enable-sms.error').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
          resolve(true);
        }
      );
    });
  }

  async enableEmailOtp(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.userOtpService.enableEmailOtp().subscribe(
        async (res: boolean) => {
          this.activeEmailOtp = res;
          this.changeDetectorRef.detectChanges();
          if (res) {
            let res = await this.getEmail();
            resolve(true);
          } else {
            resolve(true);
          }
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.otp.credentials.enable-email.error').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
          resolve(true);
        }
      );
    });
  }

  async getPhoneNumber(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.userOtpService.getPhoneNumber().subscribe(
        (res: string) => {
          this.phoneNumber = res;
          this.changeDetectorRef.detectChanges();
          resolve(true);
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.otp.credentials.phone-number.error').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
          resolve(true);
        }
      );
    });
  }

  async getEmail(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));
      if(claims.email != undefined){
        this.userEmail = claims.email;
      }else {
        this.userEmail = null;
      }
      this.changeDetectorRef.detectChanges();
      resolve(true);
    });
  }

  getPreferredDevice() {
    this.userOtpService.getPreferredDevice().subscribe(
      (res: ActiveCredential) => {
        this.preferredDevice = res;
        this.checkActivePreferredDevice();
        this.changeDetectorRef.detectChanges();
      },
      (err: HttpErrorResponse) => {
        this.translate.get('profile.otp.credentials.preferred-device.error').subscribe((text: string) => {
          this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
        });
      }
    );
  }

  setPageTitle(): string {
    let title = '';
    this.translate.get('page-title.profile').subscribe((text: string) => (title = text));
    return title;
  }

  createActiveCredential() {
    this.activeCredentials = [];
    this.userCredentials.forEach((otp) => {
      this.activeCredentials.push(new ActiveCredential("APP", otp.credential.userLabel, otp.credential.id));
    });

    if (this.activeSmsOtp) {
      if (this.phoneNumber != null) {
        this.activeCredentials.push(new ActiveCredential("SMS", this.phoneNumber, null));
      }
    }

    if (this.activeEmailOtp) {
      if (this.userEmail != null) {
        this.activeCredentials.push(new ActiveCredential("EMAIL", this.userEmail, null));
      }
    }

    this.checkActivePreferredDevice();
    this.changeDetectorRef.detectChanges();
  }

  checkActivePreferredDevice() {
    let result = false;

    if (this.preferredDevice != null) {
      this.activeCredentials.forEach((otp) => {
        if ((otp.type === this.preferredDevice.type) && (otp.value.valueOf() == this.preferredDevice.value.valueOf())) {
          if (otp.type == "OTP") {
            if (otp.otpId.valueOf() == this.preferredDevice.otpId.valueOf()) {
              result = true;
            }
          } else {
            result = true;
          }
        }
      });
    } else {
      result = true;
    }

    this.activeDevice = result;
    this.changeDetectorRef.detectChanges();
  }

  savePreferredDevice() {
    if (this.preferredDeviceForms.valid) {
      let setDevice: ActiveCredential = this.preferredDeviceForms.value.device;

      this.userOtpService.addPreferredOtp(setDevice.type, setDevice.value, setDevice.otpId).subscribe(
        (res) => {
          this.getPreferredDevice();
          this.translate.get('profile.otp.credentials.set-preferred-device.success').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-success'], });
          });
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.otp.credentials.set-preferred-device.error').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
        }
      );
    } else {
      this.preferredDeviceForms.markAllAsTouched();
    }
  }

  display(cred: ActiveCredential): string {
    let type = '';

    if (cred.type === "APP") {
      this.translate
        .get('profile.otp.credentials.otp-device-app')
        .subscribe((text: string) => (type = text));
    } else if (cred.type === "SMS") {
      this.translate
        .get('profile.otp.credentials.otp-device-sms')
        .subscribe((text: string) => (type = text));
    } else if (cred.type === "EMAIL") {
      this.translate
        .get('profile.otp.credentials.otp-device-email')
        .subscribe((text: string) => (type = text));
    }

    return type;
  }

  displayValue(cred: ActiveCredential): string {
    let translateMsg = '-';

    translateMsg = this.display(cred) + " : " + cred.value;
    return translateMsg;
  }
}
