import type {
  HttpErrorResponse,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { from, Observable, of, throwError, timer } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { UserLoginService } from '../../../shared/services/login/user-login.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthInterceptor implements HttpInterceptor {
  private userLoginService: UserLoginService;
  constructor(
    private injector: Injector,
    private router: Router,
    private authStorage: OAuthStorage
  ) {
    console.log(
      'interceptor instantiated, authStorage = ',
      authStorage != undefined
    );
    if (!authStorage) {
      console.error('authStorage should be defined');
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has('Authorization')) {
      let token = this.authStorage.getItem('access_token');

      if (token != null) {
        if (
          req.url.indexOf('/auth') > -1 ||
          req.url.indexOf('/dss/') > -1 ||
          req.url.indexOf('/crt/db/api/') > -1 ||
          req.url.indexOf('/audit/api') > -1
        ) {
          req = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token,
            },
          });
        }
      } else {
        console.log('Token expired or empty.');
      }
    }

    return next.handle(req).pipe(
      mergeMap((event: any) => {
        return of(event);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          if (error.url.includes("/protocol/openid-connect/token")) {
            this.login();
            this.router.navigateByUrl('/ui/dashboard');
          } else {
            return throwError(error);
          }
        } else if (error.status === 401) {
          let header = error.headers.get('WWW-Authenticate');
          if (header != null) {
            if (header.includes('Token is not active')) {
              return from(this.refreshToken()).pipe(
                switchMap(() => {
                  return next.handle(
                    this.addAuthToken(
                      req,
                      this.userLoginService.oauthService.getAccessToken()
                    )
                  );
                })
              );
            } else {
              this.router.navigateByUrl('/sessions/401');
            }
          } else {
            this.refreshToken();
          }
        } else if (error.status === 403) {
          this.router.navigateByUrl('/sessions/403');
        } else if (error.status === 500) {
          this.router.navigateByUrl('/sessions/500');
        } else {
          return throwError(error);
        }
      })
    );
  }

  addAuthToken(req: HttpRequest<any>, token): HttpRequest<any> {
    if (token != null) {
      if (
        req.url.indexOf('/auth') > -1 ||
        req.url.indexOf('/dss/') > -1 ||
        req.url.indexOf('/crt/db/api/') > -1 ||
        req.url.indexOf('/audit/api') > -1
      ) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
      }
    } else {
      console.log('Token expired or empty.');
    }
    return req;
  }

  refreshToken() {
    this.userLoginService = this.injector.get(UserLoginService);
    return this.userLoginService.refreshToken();
  }

  logout() {
    this.userLoginService = this.injector.get(UserLoginService);
    return this.userLoginService.logout();
  }

  login() {
    this.userLoginService = this.injector.get(UserLoginService);
    return this.userLoginService.login();
  }
}
