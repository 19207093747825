import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Menu, MenuService } from '../../core/bootstrap/menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
})
export class TopmenuComponent implements OnInit {
  menus: Observable<Menu[]>;

  constructor(public menuService: MenuService) {}

  ngOnInit() {
    this.menus = this.menuService.getAll();
  }

  // Delete empty values and rebuild route
  buildRoute(routes: string[]) {
    let route = '';
    routes.forEach(item => {
      if (item && item.trim()) {
        route += '/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return route;
  }
}
