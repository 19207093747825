<mat-card>
    <mat-tab-group (selectedTabChange)="createActiveCredential()">
        <mat-tab label='{{"profile.otp.credentials.configuration" | translate}}'>
            <div class="m-16">
                <mat-card-content>
                    <div class="otp-configuration-title">
                        {{"profile.otp.credentials.configuration-title" | translate}}
                    </div>
                    <br>

                    <div *ngIf="preferredDevice === null">
                        <div class="otp-config-preferred-device">
                            {{"profile.otp.credentials.otp-none-preferred-device" | translate}}
                        </div>
                    </div>

                    <div *ngIf="preferredDevice != null ">
                        <div class="otp-config-preferred-device">
                            {{"profile.otp.credentials.otp-preferred-device" | translate}}<br>
                            <div class="preferred-device"><span class="preferred-device-title">{{"profile.otp.credentials.otp-preferred-device-type" |
                                translate}}:</span> {{ display(preferredDevice) }} <br>
                            <span class="preferred-device-title">{{"profile.otp.credentials.otp-preferred-device-value"
                                | translate}}:</span> {{preferredDevice.value}}<br>
                            </div>
                        </div>

                        <div *ngIf="activeDevice === false" class="otp-preferred-device-inactive">
                            {{"profile.otp.credentials.otp-preferred-device-inactive" | translate}}
                        </div>
                    </div>

                    <div *ngIf="activeCredentials.length > 0 ">
                        <form [formGroup]="preferredDeviceForms" class="preferred-device-form">
                            <label class="label-device-form">{{
                                "profile.otp.credentials.otp-default-device" | translate
                                }}</label><br />
                            <mat-form-field class="choose-device-field">
                                <mat-select formControlName="device" matNativeControl required>
                                    <mat-option *ngFor="let cred of activeCredentials" [value]="cred">
                                        {{ displayValue(cred) }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="buttons-form">
                                <button (click)="savePreferredDevice()" class="m-t-8 btn-form" color="primary"
                                    mat-raised-button mat-stroked-button type="submit">
                                    {{ "profile.otp.credentials.otp-preferred-device-change-btn" | translate }}
                                </button>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="activeCredentials.length === 0 ">
                        <div class="otp-config-preferred-device">
                            {{"profile.otp.credentials.none-active-device" | translate}}
                        </div>
                    </div>

                </mat-card-content>
            </div>
        </mat-tab>
        <mat-tab label='{{"profile.otp.credentials.otp-app" | translate}}'>
            <div class="m-16">
                <mat-card-content>
                    <div class="otp-title">
                        {{"profile.otp.credentials.otp-title" | translate}}
                    </div>
                    <br>

                    <div *ngIf="userCredentials.length === 0">
                        <div class="otp-empty-list">
                            {{"profile.otp.credentials.otp-empty-list" | translate}}
                        </div>
                    </div>

                    <div *ngIf="userCredentials.length > 0">
                        <div class="otp-list-title">
                            {{"profile.otp.credentials.otp-list-title" | translate}}
                        </div>
                        <div *ngFor="let otp of userCredentials" class="otp-list">
                            <mat-card class="otp-row">
                                <div><span class="otp-name">{{"profile.otp.credentials.otp-name" | translate}}</span>
                                    {{otp.credential.userLabel}}</div>
                                <div><span class="created-date">{{"profile.otp.credentials.otp-created" |
                                        translate}}</span> {{otp.credential.createdDate |
                                    date:
                                    'yyyy-MM-dd HH:mm:ss'}}
                                </div>
                                <div><button (click)="deleteOtpCode(otp.credential.id)" class="m-t-8 btn-form" color="primary"
                                        mat-raised-button>
                                        {{"profile.otp.credentials.delete-otp-btn" | translate}}
                                    </button>
                                </div>
                            </mat-card>
                        </div>
                    </div>

                    <br>
                    <div class="buttons-form">
                        <button (click)="addOtpCode()" class="m-t-8 btn-form" color="primary" mat-raised-button>
                            {{"profile.otp.credentials.add-otp-btn" | translate}}
                        </button>
                    </div>
                </mat-card-content>
            </div>
        </mat-tab>
        <mat-tab label='{{"profile.otp.credentials.otp-sms" | translate}}'>
            <div class="m-16">
                <mat-card-content>
                    <div class="otp-title">
                        {{"profile.otp.credentials.otp-sms-title" | translate}}
                    </div>
                    <br>

                    <div *ngIf="activeSmsOtp === false">
                        <div class="sms-otp-not-configured">
                            {{"profile.otp.credentials.otp-sms-not-configured" | translate}}
                        </div>
                    </div>

                    <div *ngIf="activeSmsOtp" class="sms-otp-configured">
                        <div *ngIf="phoneNumber === null" class="otp-sms">
                            {{"profile.otp.credentials.none-phone-number" | translate}}
                        </div>
                        <div *ngIf="phoneNumber != null" class="otp-sms">
                            {{"profile.otp.credentials.otp-sms-configured" | translate}} <span class="phone-number">
                                {{phoneNumber}}</span>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </mat-tab>
        <mat-tab label='{{"profile.otp.credentials.otp-email" | translate}}'>
            <div class="m-16">
                <mat-card-content>
                    <div class="otp-title">
                        {{"profile.otp.credentials.otp-email-title" | translate}}
                    </div>
                    <br>

                    <div *ngIf="activeEmailOtp === false">
                        <div class="email-otp-not-configured">
                            {{"profile.otp.credentials.otp-email-not-configured" | translate}}
                        </div>
                    </div>

                    <div *ngIf="activeEmailOtp" class="email-otp-configured">
                        <div *ngIf="userEmail === null" class="otp-email">
                            {{"profile.otp.credentials.none-email" | translate}}
                        </div>
                        <div *ngIf="userEmail != null" class="otp-email">
                            {{"profile.otp.credentials.otp-email-configured" | translate}} <span class="email">
                                {{userEmail}}</span>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>