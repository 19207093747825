import { SelectedItem } from '../selected-item/selected-item';

export interface SelectedItemsExtraI {
    items: Array<SelectedItem>;
    currentItem: string;
    currentTitle: string;
    currentDescription: string;
    currentIsSigned: boolean;
}

export class SelectedItemsExtra implements SelectedItemsExtraI {
    items: Array<SelectedItem>;
    currentItem: string;
    currentTitle: string;
    currentDescription: string;
    currentIsSigned: boolean;

    constructor(items: Array<SelectedItem>, currentTitle: string, currentItem: string, currentDescription: string, currentIsSigned: boolean) {
        this.items = items;
        this.currentItem = currentItem;
        this.currentTitle = currentTitle;
        this.currentDescription = currentDescription;
        this.currentIsSigned = currentIsSigned;
    }
}
