import { Injectable, Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateLangService {
  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private settings: SettingsService
  ) {}

  load() {
    return new Promise<void>((resolve) => {
      const locationInitialized = this.injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve()
      );
      locationInitialized.then(() => {
        const browserLang = navigator.language;

        let defaultLang;
        if (sessionStorage.getItem('lang') != null) {
          var lang = sessionStorage.getItem('lang');
          if (lang === 'pl' || lang === 'pl-PL') {
            defaultLang = 'pl-PL';
          } else if (lang === 'en' || lang === 'en-US') {
            defaultLang = 'en-US';
          } else {
            defaultLang = browserLang.match(/pl-PL|en-US/) ? browserLang : 'pl-PL';
          }
        } else {
          defaultLang = browserLang.match(/pl-PL|en-US/) ? browserLang : 'pl-PL';
        }

        this.settings.setLanguage(defaultLang);
        this.translate.setDefaultLang(defaultLang);
        this.translate.use(defaultLang).subscribe(
          () =>
            console.log(`Successfully initialized '${defaultLang}' language.'`),
          () =>
            console.error(
              `Problem with '${defaultLang}' language initialization.'`
            ),
          () => resolve()
        );
      });
    });
  }

  public setLanguage(language: string) {
    this.translate.use(language).subscribe(
      () => {
        console.log(`Successfully initialized '${language}' language.'`);
        sessionStorage.removeItem('lang');
        sessionStorage.setItem('lang', language);
      },
      () =>
        console.error(`Problem with '${language}' language initialization.'`),
      () => console.log(`Finalize initialized '${language}' language.'`)
    );
  }

  public getCurrentLanguage() {
    return this.translate.store.currentLang;
  }
}
