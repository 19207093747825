import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@esign/material';
import { TranslateService } from '@ngx-translate/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppGlobalService } from '../../../core/bootstrap/app-global/app-global.service';
import {
  DEFAULT_USER_PHOTO,
  DEFAULT_USER_PHOTO_DOWNLOADED,
  UserPhotoI,
} from '../../../core/bootstrap/interfaces/user-photo.interface';

@Injectable({
  providedIn: 'root',
})
export class UserPhotoService {

  isDownloadUserRole:boolean = false;
  userRoles: Array<String> = new Array();

  constructor(
    private appGlobal: AppGlobalService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private oauthStorage: OAuthStorage
  ) { }

  load(): Promise<UserPhotoI> {
    return new Promise((resolve, reject) => {
      if (this.oauthStorage.getItem('access_token') === null || this.isExpiredToken()) {
        timer(1000)
          .toPromise()
          .then(() => {
            this.load();
          });
        resolve(DEFAULT_USER_PHOTO);
      } else {
        this.hasAccesDownloadPhoto()
          .then((res: boolean) => {
            if (!res) {
              this.appGlobal.isDownloadedPhoto = true;
              resolve(DEFAULT_USER_PHOTO);
            } else if (!this.appGlobal.isDownloadedPhoto) {
              this.appGlobal.isDownloadedPhoto = true;
              this.http
                .get('/auth/realms/esign/user-avatar/esign-backend/avatar/download')
                .pipe(
                  catchError(res => {
                    reject(res);
                    return res;
                  })
                )
                .subscribe(
                  (userPhoto: any) => {
                    if (userPhoto && userPhoto.photo && userPhoto.photo != "") {
                      var byteString = atob(userPhoto.photo);
                      var ab = new ArrayBuffer(byteString.length);
                      var ia = new Uint8Array(ab);

                      for (var i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                      }
                      const objectURL = URL.createObjectURL(new Blob([ab], { type: 'image/png' }));
                      this.appGlobal.photo = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
                    }
                    resolve(this.appGlobal.userPhoto);
                  },
                  () => {
                    let errorMessage;
                    this.translate
                      .get('profile.avatar.download.error')
                      .subscribe((text: string) => (errorMessage = text));

                    this.snackBar.open(errorMessage, '', {
                      duration: 4000,
                      panelClass: ['snack-error'],
                    });

                    this.appGlobal.photo = DEFAULT_USER_PHOTO_DOWNLOADED;
                    resolve(this.appGlobal.photo);
                  },
                  () => { }
                );
            }
          })
      }
    });
  }

  async hasAccesDownloadPhoto() {
    return this.hasRole("user_profile");
  }

  isExpiredToken(): boolean {
    let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));
    let exp = claims.exp;

    if (exp === null) {
      return true;
    } else if (exp >= (Math.floor(Date.now() / 1000))) {
      return false;
    } else {
      return true;
    }
  }

  public async hasRole(role:string){
    var res = await this.downloadUserRole();
    if(this.userRoles.includes("super_admin".valueOf()).valueOf()){
      return new Promise((resolve, reject) => { resolve(true); })
    }

    for (var i = 0, len = this.userRoles.length; i < len; i++) {
      if(this.userRoles[i].valueOf() === role){
        return new Promise((resolve, reject) => { resolve(true); })
      }
    }
    return new Promise((resolve, reject) => { resolve(false); })
  }

  async downloadUserRole(): Promise<boolean> {
    if(this.isDownloadUserRole){
      return;
    }
    
    if (this.oauthStorage.getItem('id_token_claims_obj') === null) {
      timer(500)
        .toPromise()
        .then(() => {
          this.isDownloadUserRole = false;
          this.downloadUserRole();
        });
    } else {
      let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));    
      let roles: Array<string> = claims.role;   
      return new Promise(async (resolve, reject) => {
        this.userRoles = roles;
        this.isDownloadUserRole = true;
        resolve(true);
      });
    }
  }

}
