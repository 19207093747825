import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GuardService } from './service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class InnerSignGuard implements CanActivate {
  constructor(
    private guardService: GuardService
  ) { }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isEnableInnerSign();
  }

  isEnableInnerSign(): Observable<boolean | UrlTree> {
    return this.guardService.activeInnerSign()
      .pipe(
        map((status) => {
          if (status === true) {
            return true;
          } else {
            return false;
          }
        })
      );
  }
}
