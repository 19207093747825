<div [dir]="options.dir"
     [ngClass]="{'matero-sidenav-collapsed': options.sidenavCollapsed && options.navPos!=='top',
                 'matero-navbar-side': options.navPos==='side',
                 'matero-navbar-top': options.navPos==='top',
                 'matero-header-above': options.headerPos==='above',
                 'matero-header-fixed': options.headerPos==='fixed',
                 'matero-header-primary': options.theme==='light'}"
     class="matero-container-wrap">

  <ng-progress ngProgressRouter />

  <!-- Header Above -->
  <app-user-header #header (toggleSidenav)="sidenav.toggle()"
                   *ngIf="options.showHeader && options.headerPos==='above'"
                   [showBranding]="true"
                   [showToggle]="false"
                   class="matero-header">
  </app-user-header>

  <mat-sidenav-container autoFocus autosize class="matero-container">

    <mat-sidenav-content #content class="matero-content-wrap">
      <app-user-header #header (toggleSidenav)="sidenav.toggle()"
                       *ngIf="options.showHeader && options.headerPos!=='above'"
                       [showBranding]="true"
                       [showToggle]="false"
                       class="matero-header">
      </app-user-header>

      <div class="matero-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
