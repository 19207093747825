export interface StatsI {
  title: string;
  value: string;
  color: string;
}

export class Stats implements StatsI {
  title: string;
  value: string;
  color: string;

  constructor(title: string, value: string, color: string) {
    this.title = title;
    this.value = value;
    this.color = color;
  }
}
