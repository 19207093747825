import {
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { PageTitleService } from '@esign/common';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserSignVisualizationService } from '../../../services/user-sign-visualization/user-sign-visualization.service';
import { UploadPhoto } from './models/upload-photo';

@Component({
  selector: 'lib-esign-user-sign-visualization',
  templateUrl: './user-sign-visualization.component.html',
  styleUrls: ['./user-sign-visualization.component.css'],
})
export class UserSignVisualizationComponent implements OnInit {
  isUploadDefaultPhoto: boolean = false;
  isUploadPhoto: boolean = false;
  configImageForSeal: boolean = false;
  photoForm: FormGroup;
  showFormatError = false;
  defaultPhotoImagePath;
  photoImagePath;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private pageTitleService: PageTitleService,
    private signVisualizationService: UserSignVisualizationService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.pageTitleService.title = this.setPageTitle();
    this.initPhotoForm();
  }

  async ngOnInit() {
    this.isSettingsDefaultPhoto();
    let res = await this.getImagePathDefaultPhoto();
    let res1 = await this.getConfigImageForSeal();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.pageTitleService.title = this.setPageTitle();
    });
  }

  setPageTitle(): string {
    let title = '';
    this.translate
      .get('page-title.profile')
      .subscribe((text: string) => (title = text));
    return title;
  }

  initPhotoForm() {
    this.photoForm = this.fb.group({
      photo: ['', [Validators.required]],
      signType: [''],
    });
  }

  async getImagePathDefaultPhoto() {
    this.signVisualizationService.downloadUserDefaultPhoto().subscribe(
      (photo) => {
        const objectURL = URL.createObjectURL(
          new Blob([photo], { type: 'image/png' })
        );
        this.defaultPhotoImagePath =
          this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
        this.cdr.detectChanges();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.signVisualizationService.downloadDefaultPhoto().subscribe(
            (photo) => {
              const objectURL = URL.createObjectURL(
                new Blob([photo], { type: 'image/png' })
              );
              this.defaultPhotoImagePath =
                this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
              this.cdr.detectChanges();
            },
            () => {
              let errorMessage;
              this.translate
                .get('profile.sign-visualization.photo.download.error')
                .subscribe((text: string) => (errorMessage = text));

              this.snackBar.open(errorMessage, '', {
                duration: 4000,
                panelClass: ['snack-error'],
              });
            }
          );
        } else {
          let errorMessage;
          this.translate
            .get('profile.sign-visualization.photo.download.error')
            .subscribe((text: string) => (errorMessage = text));

          this.snackBar.open(errorMessage, '', {
            duration: 4000,
            panelClass: ['snack-error'],
          });
        }
      }
    );
  }

  async getConfigImageForSeal() {
    this.signVisualizationService.configImageForSeal().subscribe(
      (res: boolean) => {
        this.configImageForSeal = res;
        this.cdr.detectChanges();
      },
      () => {
        let errorMessage;
        this.translate
          .get('profile.sign-visualization.config-for-seal.error')
          .subscribe((text: string) => (errorMessage = text));

        this.snackBar.open(errorMessage, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });
      }
    );
  }

  async getImagePathPhoto(signType) {
    this.signVisualizationService.downloadSignTypePhoto(signType).subscribe(
      (photo) => {
        const objectURL = URL.createObjectURL(
          new Blob([photo], { type: 'image/png' })
        );
        this.photoImagePath =
          this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
        this.cdr.detectChanges();
      },
      () => {
        let errorMessage;
        this.translate
          .get('profile.sign-visualization.photo.download.error')
          .subscribe((text: string) => (errorMessage = text));

        this.snackBar.open(errorMessage, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });
      }
    );
  }

  deleteDefaultPhoto() {
    this.signVisualizationService.deleteDefaultPhoto().subscribe(
      () => {
        this.isSettingsDefaultPhoto();
        this.getImagePathDefaultPhoto();
        let message;
        this.translate
          .get('profile.sign-visualization.delete.default.success')
          .subscribe((text: string) => (message = text));

        this.snackBar.open(message, '', {
          duration: 4000,
          panelClass: ['snack-success'],
        });
      },
      () => {
        let errorMessage;
        this.translate
          .get('profile.sign-visualization.delete.default.error')
          .subscribe((text: string) => (errorMessage = text));

        this.snackBar.open(errorMessage, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });
      },
      () => {}
    );
  }

  deletePhoto(signType) {
    this.signVisualizationService.deletePhoto(signType).subscribe(
      () => {
        this.isSettingsPhoto(signType);
        let message;
        this.translate
          .get('profile.sign-visualization.delete.success')
          .subscribe((text: string) => (message = text));

        this.snackBar.open(message, '', {
          duration: 4000,
          panelClass: ['snack-success'],
        });
      },
      () => {
        let errorMessage;
        this.translate
          .get('profile.sign-visualization.delete.error')
          .subscribe((text: string) => (errorMessage = text));

        this.snackBar.open(errorMessage, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });
      },
      () => {}
    );
  }

  uploadDefaultPhoto() {
    this.photoForm.controls.signType.setValue('default');
    if (this.photoForm.valid) {
      this.blobToBase64(this.photoForm.controls.photo.value[0]).then((res) => {
        let photo = new UploadPhoto(
          res,
          this.photoForm.controls.signType.value
        );
        this.signVisualizationService.uploadPhoto(photo).subscribe(
          () => {
            this.cleanForm();

            this.translate
              .get('profile.sign-visualization.popoup.success-upload')
              .subscribe((text: string) => {
                this.snackBar.open(text, '', {
                  duration: 4000,
                  panelClass: ['snack-success'],
                });
              });
            this.showFormatError = false;

            this.isSettingsDefaultPhoto();
            this.getImagePathDefaultPhoto();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 400) {
              this.translate
                .get('profile.sign-visualization.popoup.failed-maximum-size')
                .subscribe((text: string) => {
                  this.snackBar.open(text, '', {
                    duration: 4000,
                    panelClass: ['snack-error'],
                  });
                });
            } else {
              this.translate
                .get('profile.sign-visualization.popoup.failed-upload')
                .subscribe((text: string) => {
                  this.snackBar.open(text, '', {
                    duration: 4000,
                    panelClass: ['snack-error'],
                  });
                });
            }
          }
        );
      });
    } else {
      this.photoForm.markAllAsTouched();
    }
  }

  uploadPhoto(signType) {
    this.photoForm.controls.signType.setValue(signType);
    if (this.photoForm.valid) {
      this.blobToBase64(this.photoForm.controls.photo.value[0]).then((res) => {
        let photo = new UploadPhoto(
          res,
          this.photoForm.controls.signType.value
        );
        this.signVisualizationService.uploadPhoto(photo).subscribe(
          () => {
            this.cleanForm();

            this.translate
              .get('profile.sign-visualization.popoup.success-upload')
              .subscribe((text: string) => {
                this.snackBar.open(text, '', {
                  duration: 4000,
                  panelClass: ['snack-success'],
                });
              });
            this.showFormatError = false;

            this.isSettingsPhoto(signType);
          },
          (err: HttpErrorResponse) => {
            if (err.status === 400) {
              this.translate
                .get('profile.sign-visualization.popoup.failed-maximum-size')
                .subscribe((text: string) => {
                  this.snackBar.open(text, '', {
                    duration: 4000,
                    panelClass: ['snack-error'],
                  });
                });
            } else {
              this.translate
                .get('profile.sign-visualization.popoup.failed-upload')
                .subscribe((text: string) => {
                  this.snackBar.open(text, '', {
                    duration: 4000,
                    panelClass: ['snack-error'],
                  });
                });
            }
          }
        );
      });
    } else {
      this.photoForm.markAllAsTouched();
    }
  }

  async blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  isSettingsDefaultPhoto() {
    this.signVisualizationService
      .isSettingsDefaultPhoto()
      .subscribe((res: boolean) => {
        this.isUploadDefaultPhoto = res;
        this.cdr.detectChanges();
      });
  }

  isSettingsPhoto(signType) {
    this.signVisualizationService
      .isSettingsPhoto(signType)
      .subscribe((res: boolean) => {
        this.isUploadPhoto = res;
        if (res) {
          this.getImagePathPhoto(signType);
        }
        this.cdr.detectChanges();
      });
  }

  cleanForm() {
    this.photoForm.reset();
  }

  selectFile(event) {
    if (
      event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpg' ||
      event.target.files[0].type === 'image/jpeg'
    ) {
      this.photoForm.controls.photo.setValue(event.target.files);
      this.showFormatError = false;
    } else {
      this.showFormatError = true;
    }
  }

  public signTypeChange(matTabChangeEvent: MatTabChangeEvent) {
    let signType;

    if (this.configImageForSeal) {
      if (matTabChangeEvent.index === 0) {
        signType = 'DEFAULT';
      } else if (matTabChangeEvent.index === 1) {
        signType = 'QUALIFIED';
      } else if (matTabChangeEvent.index === 2) {
        signType = 'SEAL';
      } else if (matTabChangeEvent.index === 3) {
        signType = 'SEAL_TRUSTED';
      } else if (matTabChangeEvent.index === 4) {
        signType = 'INNER';
      } else if (matTabChangeEvent.index === 5) {
        signType = 'SEAL_INNER';
      } else if (matTabChangeEvent.index === 6) {
        signType = 'SEAL_INNER_TRUSTED';
      }
    } else {
      if (matTabChangeEvent.index === 0) {
        signType = 'DEFAULT';
      } else if (matTabChangeEvent.index === 1) {
        signType = 'QUALIFIED';
      } else if (matTabChangeEvent.index === 2) {
        signType = 'INNER';
      }
    }

    this.isSettingsDefaultPhoto();
    this.getImagePathDefaultPhoto();

    if (signType != 'DEFAULT') {
      this.isSettingsPhoto(signType);
    }
  }
}
