import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AppGlobalService, UserLoginService } from '@esign/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-profile-layout',
  templateUrl: './user-profile-layout.component.html',
  styleUrls: ['./user-profile-layout.component.scss'],
})
export class UserProfileLayoutComponent implements OnInit {

  pageTitle: string = '';
  isAccessOtp = false;
  isAccessUserProfile = false;
  isAccessVisualization = false;

  constructor(public appGlobal: AppGlobalService, private translate: TranslateService,
    private userLoginService: UserLoginService) {
    this.translate
      .stream('profile.menu.profile')
      .subscribe((text: string) => (this.pageTitle = text));
  }

  async ngOnInit() {
    let res = await this.hasAccesUserProfile();
    let res1 = await this.hasAccesOtp();
    let res2 = await this.hasAccesVisualization();
  }

  getImagePath() {
    return this.appGlobal.imagePath;
  }

  async hasAccesUserProfile() {
    this.userLoginService.hasRole("user_profile")
      .then((res: boolean) => {
        this.isAccessUserProfile = res;
      });
  }

  async hasAccesOtp() {
    this.userLoginService.hasRole("otp")
      .then((res: boolean) => {
        this.isAccessOtp = res;
      });
  }

  async hasAccesVisualization() {
    this.userLoginService.hasRole("user_profile_sign_visualization")
      .then((res: boolean) => {
        this.isAccessVisualization = res;
      });
  }

}
