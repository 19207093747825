<mat-card>
  <mat-card-title>{{ 'profile.avatar.title' | translate }}</mat-card-title>
  <mat-card-content>
    <hr />

    <div class="form-photo">
      <img [src]="getImagePath()" alt="" class="m-b-8 r-full img-current" width="100" />
      <div *ngIf="isUploadPhoto()" class="delete-photo-btn">
        <button
          #deletePhotoBtn
          (click)="deletePhoto()"
          class="m-t-8"
          class="btn-form"
          color="primary"
          mat-raised-button
        >
          {{ 'profile.avatar.btn-remove-photo' | translate }}
        </button>
      </div>

      <hr class="horizontal-line" />

      <div class="info-header">{{ 'profile.avatar.update-photo' | translate }}</div>

      <div class="form">
        <form [formGroup]="imageForm" class="matero-form-horizontal">
          <mat-form-field class="field-form">
            <mat-label *ngIf="imageForm.controls.image.value; else newFile"
              >{{ imageForm.controls.image.value[0].name }}
            </mat-label>
            <ng-template #newFile>
              <mat-label>{{ 'profile.avatar.choose-file' | translate }}</mat-label>
            </ng-template>
            <input disabled matInput />
            <button (click)="fileInput.click()" mat-icon-button matSuffix>
              <mat-icon class="attach-icon">attach_file</mat-icon>
            </button>
            <input
              #fileInput
              (change)="selectFile($event)"
              (click)="fileInput.value = null"
              accept=".jpg,.jpeg,.png"
              hidden
              id="file"
              type="file"
            />
          </mat-form-field>

          <div *ngIf="showFormatError" class="help-block error small" id="error-in-upload-photo">
            {{ 'profile.avatar.form-error' | translate }}
          </div>
        </form>

        <div class="buttons-form">
          <button
            (click)="cleanForm()"
            [disabled]="imageForm.invalid"
            class="m-t-8"
            class="btn-form"
            color="primary"
            mat-raised-button
          >
            {{ 'profile.avatar.btn-clear-form' | translate }}
          </button>

          <button
            (click)="uploadImage()"
            [disabled]="imageForm.invalid"
            class="m-t-8"
            class="btn-form"
            color="primary"
            mat-raised-button
          >
            {{ 'profile.avatar.btn-save-photo' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
