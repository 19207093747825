import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@esign/material';
import { TranslateService } from '@ngx-translate/core';
import { CertificateDetails, CertificateDetailsI } from '../../models/certificate-details';
import { CertificateService } from '../../services/certificate/certificate.service';

@Component({
  selector: 'lib-esign-common-certificate-details',
  templateUrl: './certificate-details.component.html',
  styleUrls: ['./certificate-details.component.css'],
})
export class CertificateDetailsComponent implements OnInit {
  certificateId: string;
  certificateDetails: CertificateDetails;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private certificateService: CertificateService,
    private snackBar: MatSnackBar,
    private location: Location,
  ) {
    this.certificateId = this.activatedRoute.snapshot.params.certificateId;
    this.certificateDetails = new CertificateDetails();

    activatedRoute.queryParams.subscribe(params => (this.certificateId = params['certificateId']));
  }

  ngOnInit(): void {
    this.certificateService.detailsCertificate(this.certificateId).subscribe(
      (res: CertificateDetailsI) => {
        this.certificateDetails.setValue(res);
      },
      () => {
        let errorMessage;
        this.translate
          .get('certificate.active.get.error')
          .subscribe((text: string) => (errorMessage = text));

        this.snackBar.open(errorMessage, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });
      }
    );
  }

  back() {
    this.location.back();
  }
}
