<div class="profile-info">
  <div class="info-header">{{ 'profile.data.personal-data' | translate }}</div>
  <div class="row-info">
    <label class="header-info">{{ 'profile.data.first-name' | translate }}: </label><span class="value-info">{{
      userData.firstName }}</span>
  </div>

  <div class="row-info">
    <label class="header-info">{{ 'profile.data.last-name' | translate }}: </label><span class="value-info">{{
      userData.lastName }}</span>
  </div>

  <div class="row-info">
    <label class="header-info">{{ 'profile.data.email' | translate }}: </label><span class="value-info">{{
      userData.email }}</span>
  </div>

  <div class="row-info">
    <label class="header-info">{{ 'profile.data.phone-number' | translate }}: </label><span class="value-info">{{
      userData.mobileNumber }}</span>
  </div>

  <div class="row-info">
    <label class="header-info">{{ 'profile.data.display-name' | translate }}: </label><span class="value-info">{{
      userData.displayName }}</span>
  </div>

  <!-- <div class="btn-edit">
    <button (click)="forwardingToUpdate()" class="m-t-8" color="primary" mat-raised-button>
      {{ "profile.data.btn-edit" | translate }}
    </button>
  </div> -->

  <hr />

  <div class="info-header">{{ 'profile.data.account-info' | translate }}</div>
  <div class="row-info">
    <label class="header-info">{{ 'profile.data.usos-id' | translate }}: </label><span class="value-info">{{
      userData.usosId }}</span>
  </div>
  <div class="row-info">
    <label class="header-info">{{ 'profile.data.creation-account-date' | translate }}: </label><span
      class="value-info">{{ userData.creationDate | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
  </div>
  <div class="row-info">
    <label class="header-info">{{ 'profile.data.last-modified-account-date' | translate }}: </label><span
      class="value-info">{{ userData.lastModifiedDate | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
  </div>
</div>