export interface CertificateDetailsI {
  identityId: string;
  notAfter: string;
  notBefore: string;
  subjectName: string;
  issuerSubjectName: string;
  serialNumber: string;
  signatureAlgorithm: string;
  thumbprint: string;
  status: string;
}

export class CertificateDetails implements CertificateDetailsI {
  identityId: string;
  notAfter: string;
  notBefore: string;
  subjectName: string;
  issuerSubjectName: string;
  serialNumber: string;
  signatureAlgorithm: string;
  thumbprint: string;
  status: string;

  constructor() {
    this.setValue(EMPTY_CERTIFICATE_DETAILS);
  }

  setValue(value: CertificateDetailsI) {
    this.identityId = value.identityId;
    this.notAfter = value.notAfter;
    this.notBefore = value.notBefore;
    this.subjectName = value.subjectName;
    this.issuerSubjectName = value.issuerSubjectName;
    this.serialNumber = value.serialNumber;
    this.signatureAlgorithm = value.signatureAlgorithm;
    this.thumbprint = value.thumbprint;
    this.status = value.status;
  }

  getValue(): CertificateDetailsI {
    return {
      identityId: this.identityId,
      notAfter: this.notAfter,
      notBefore: this.notBefore,
      subjectName: this.subjectName,
      issuerSubjectName: this.issuerSubjectName,
      serialNumber: this.serialNumber,
      signatureAlgorithm: this.signatureAlgorithm,
      thumbprint: this.thumbprint,
      status: this.status
    }
  }
}

export const EMPTY_CERTIFICATE_DETAILS: CertificateDetailsI = {
  identityId: '',
  notAfter: '',
  notBefore: '',
  subjectName: '',
  issuerSubjectName: '',
  serialNumber: '',
  signatureAlgorithm: '',
  thumbprint: '',
  status: ''
}
