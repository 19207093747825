import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Menu, MenuService } from './menu.service';
import { throwError, timer } from 'rxjs';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  isDownloadActiveInnerSign: boolean = false;
  isDownloadActiveSealInnerSign: boolean = false;

  isActiveInnerSign: boolean = false;
  isActiveSealInnerSign: boolean = false;

  isDownloadUserRole: boolean = false;
  userRoles: Array<String> = new Array();

  constructor(
    private menuService: MenuService,
    private http: HttpClient,
    private oauthStorage: OAuthStorage
  ) {}

  activeInnerSign() {
    return this.http
      .get('/dss/api/v1/sign/active/inner-sign')
      .pipe(catchError(this.errorHandler));
  }

  activeSealInnerSign() {
    return this.http
      .get('/dss/api/v1/sign/active/seal-inner-sign')
      .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  async checkActiveInnerSign(): Promise<boolean> {
    if (
      this.oauthStorage.getItem('access_token') === null ||
      this.isExpiredToken()
    ) {
      timer(500)
        .toPromise()
        .then(() => {
          this.isDownloadActiveInnerSign = false;
          this.checkActiveInnerSign();
        });
    } else {
      if (this.checkRole('internal_certificate')) {
        return new Promise(async (resolve, reject) => {
          this.activeInnerSign().subscribe(
            async (res: boolean) => {
              this.isActiveInnerSign = res;
              this.isDownloadActiveInnerSign = true;
              resolve(res);
            },
            () => {
              this.isActiveInnerSign = false;
              this.isDownloadActiveInnerSign = true;
              resolve(false);
            }
          );
        });
      } else {
        this.isDownloadActiveInnerSign = true;
        this.isActiveInnerSign = false;
        return new Promise(async (resolve, reject) => {
          resolve(true);
        });
      }
    }
  }

  async checkActiveSealInnerSign(): Promise<boolean> {
    if (
      this.oauthStorage.getItem('access_token') === null ||
      this.isExpiredToken()
    ) {
      timer(500)
        .toPromise()
        .then(() => {
          this.isDownloadActiveSealInnerSign = false;
          this.checkActiveInnerSign();
        });
    } else {
      if (this.checkRole('internal_certificate')) {
        return new Promise(async (resolve, reject) => {
          this.activeSealInnerSign().subscribe(
            async (res: boolean) => {
              this.isActiveSealInnerSign = res;
              this.isDownloadActiveSealInnerSign = true;
              resolve(res);
            },
            () => {
              this.isActiveSealInnerSign = false;
              this.isDownloadActiveSealInnerSign = true;
              resolve(false);
            }
          );
        });
      } else {
        this.isDownloadActiveSealInnerSign = true;
        this.isActiveSealInnerSign = false;
        return new Promise(async (resolve, reject) => {
          resolve(true);
        });
      }
    }
  }

  async downloadUserRole(): Promise<boolean> {
    if (this.oauthStorage.getItem('id_token_claims_obj') === null) {
      timer(500)
        .toPromise()
        .then(() => {
          this.isDownloadUserRole = false;
          this.downloadUserRole();
        });
    } else {
      let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));
      let roles: Array<string> = claims.role;
      return new Promise(async (resolve, reject) => {
        this.userRoles = roles;
        this.isDownloadUserRole = true;
        resolve(true);
      });
    }
  }

  async load(): Promise<any> {
    var res = await this.downloadUserRole();
    var res1 = await this.checkActiveInnerSign();
    var res2 = await this.checkActiveSealInnerSign();
    if (this.isDownloadActiveInnerSign && this.isDownloadActiveSealInnerSign) {
      return new Promise<void>((resolve, reject) => {
        this.http
          .get('assets/data/menu.json?_t=' + Date.now())
          .pipe(
            catchError((res) => {
              resolve();
              return res;
            })
          )
          .subscribe(
            (res: any) => {
              this.menuService.addNamespace(res.menu, 'menu');
              this.menuService.set(this.checkUserRole(res.menu, this.isActiveInnerSign, this.isActiveSealInnerSign));
            },
            () => {
              reject();
            },
            () => {
              resolve();
            }
          );
      });
    } else {
      timer(100)
        .toPromise()
        .then(() => {
          this.load();
        });
    }
  }

  checkUserRole(menu: Menu[], activeInnerSign: boolean, activeSealInnerSign: boolean): Menu[] {
    if (!this.checkRole('audit_admin_list')) {
      this.removeFromMenu(menu, 'ui/logs', 'system-audit');
    }

    if (!this.checkRole('audit_user_list')) {
      this.removeFromMenu(menu, 'ui/logs', 'audit');
    }

    if (!this.checkRole('order_list')) {
      this.removeFromMenu(menu, 'ui/orders', 'list');
    }

    if (!this.checkRole('order_all_list')) {
      this.removeFromMenu(menu, 'ui/orders', 'all-list');
    }

    if (!this.checkRole('documents_list')) {
      this.removeFromMenu(menu, 'ui/documents', null);
    }

    if (!this.checkRole('settings_sign_visualization')) {
      this.removeFromMenu(menu, 'ui/settings', 'sign-visualization');
    }

    if (!activeInnerSign || !this.checkRole('internal_certificate')) {
      this.removeFromMenu(menu, 'ui/certificates', 'list');
    }
    
    if (!activeInnerSign || !this.checkRole('internal_certificate_all_list')) {
      this.removeFromMenu(menu, 'ui/certificates', 'all-list');
    }

    if (!activeSealInnerSign || !this.checkRole('internal_certificate_seal_list')) {
      this.removeFromMenu(menu, 'ui/certificates', 'seal');
    }

    this.removeFromEmptyMenu(menu, 'ui/logs');
    this.removeFromEmptyMenu(menu, 'ui/orders');
    this.removeFromEmptyMenu(menu, 'ui/documents');
    this.removeFromEmptyMenu(menu, 'ui/certificates');
    this.removeFromEmptyMenu(menu, 'ui/settings');

    return menu;
  }

  removeFromMenu(menu: Menu[], route: string, children: string) {
    if (children === null) {
      menu.forEach(function (item, index, object) {
        if (item.route === route) {
          object.splice(index, 1);
        }
      });
    } else {
      menu.forEach(function (item, index, object) {
        if (item.route === route) {
          item.children.forEach(function (
            itemChildren,
            indexChildren,
            objectChildren
          ) {
            if (itemChildren.route === children) {
              objectChildren.splice(indexChildren, 1);
            }
          });
        }
      });
    }
  }

  removeFromEmptyMenu(menu: Menu[], route: string) {
    menu.forEach(function (item, index, object) {
      if (item.route === route) {
        if (
          item.children === undefined ||
          item.children === null ||
          item.children.length === 0
        ) {
          object.splice(index, 1);
        }
      }
    });
  }

  checkRole(role: string): boolean {
    for (var i = 0, len = this.userRoles.length; i < len; i++) {
      if (
        this.userRoles[i].valueOf() === 'super_admin' ||
        this.userRoles[i].valueOf() === role
      ) {
        return true;
      }
    }
    return false;
  }

  isExpiredToken(): boolean {
    let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));
    let exp = claims.exp;

    if (exp === null) {
      return true;
    } else if (exp >= Math.floor(Date.now() / 1000)) {
      return false;
    } else {
      return true;
    }
  }
}
