import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@esign/material';
import { SharedModule } from './shared/shared.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://esign.drp.dev'],
        sendAccessToken: true,
      },
    }),
  ],
  exports: [SharedModule]
})
export class EsignCommonModule {}
