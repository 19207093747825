import { Injectable } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Stats } from '@esign/common';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@esign/material';

@Injectable({
  providedIn: 'root',
})
export class UserStatsService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  downloadStats(): Observable<any> {
    return this.http.get('/dss/api/v1/stats/get').pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  getStats(): Promise<Array<Stats>> {
    return new Promise((resolve, reject) => {
      let stats: Array<Stats> = [];
      this.downloadStats().subscribe(
        res => {
          var json = JSON.parse(JSON.stringify(res));

          let message = '';
          let messageEmpty = '';

          this.translate
            .stream('stats.empty-date')
            .subscribe((text: string) => (messageEmpty = text));

          this.translate
            .stream('stats.last-login-date')
            .subscribe((text: string) => (message = text));
          if (json.lastLoginDate != null) {
            stats.push(new Stats(message, json.lastLoginDate, 'bg-indigo-500'));
          } else stats.push(new Stats(message, messageEmpty, 'bg-indigo-500'));

          this.translate
            .stream('stats.last-login-time')
            .subscribe((text: string) => (message = text));
          if (json.lastLoginTime != null) {
            stats.push(new Stats(message, json.lastLoginTime, 'bg-green-500'));
          } else stats.push(new Stats(message, messageEmpty, 'bg-green-500'));

          this.translate
            .stream('stats.count-documents')
            .subscribe((text: string) => (message = text));
          stats.push(new Stats(message, json.numberDocuments, 'bg-blue-500'));

          this.translate.stream('stats.count-signs').subscribe((text: string) => (message = text));
          stats.push(new Stats(message, json.numberSigns, 'bg-green-500'));

          this.translate
            .stream('stats.count-signs-documents')
            .subscribe((text: string) => (message = text));
          stats.push(new Stats(message, json.numberSignDocuments, 'bg-teal-500'));
        },
        () => {
          let errorMessage;
          this.translate
            .get('stats.download.error')
            .subscribe((text: string) => (errorMessage = text));

          this.snackBar.open(errorMessage, '', {
            duration: 4000,
            panelClass: ['snack-error'],
          });

          reject();
        },
        () => {
          resolve(stats);
        }
      );
    });
  }

  getTitle(translateName) {
    let title;
    this.translate.get(translateName).subscribe((text: string) => (title = text));
    return title;
  }
}
