import { Component, NgModule, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@esign/material';
import {
  EsignCommonModule,
  SharedModule,
  UserLoginService,
} from '@esign/common';
import {
  SharedModule as MateroSharedModule,
  ESignFooterModule,
} from '@ng-matero/common';
import { PageTitleService } from '@esign/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-esign-homepage',
  templateUrl: './homepage.html',
  styleUrls: ['./homepage.scss'],
})
export class HomepageComponent implements OnInit {
  constructor(
    private pageTitleService: PageTitleService,
    private userLoginService: UserLoginService
  ) {}

  ngOnInit(): void {
    this.pageTitleService.title = '';
  }

  handleLoginClick(event: Event): void {
    this.userLoginService.login(true);
  }
}

@NgModule({
  imports: [
    MatButtonModule,
    RouterModule,
    CommonModule,
    SharedModule,
    EsignCommonModule,
    MateroSharedModule,
    ESignFooterModule,
    TranslateModule,
  ],
  exports: [HomepageComponent],
  declarations: [HomepageComponent],
})
export class HomepageModule {}
