import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import {
  EMPTY_USER_DETAILS,
  UserDetails,
  UserDetailsI,
} from '../../../core/bootstrap/interfaces/user-details.interface';
import { timer } from 'rxjs';
import { AppGlobalService } from '../../../core/bootstrap/app-global/app-global.service';
import { UserLoginService } from '../login/user-login.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService implements OnInit {
  constructor(
    private appGlobal: AppGlobalService,
    private http: HttpClient,
    private userLoginService: UserLoginService
  ) {}

  ngOnInit(): void {}

  load(): Promise<UserDetailsI> {
    console.log('Load UserService');
    return new Promise((resolve, reject) => {
      if (!this.userLoginService.oauthService.hasValidIdToken()) {
        timer(1000)
          .toPromise()
          .then(() => {
            this.load();
          });
        resolve(EMPTY_USER_DETAILS);
      } else {
        let userDetail = new UserDetails();
        userDetail.displayName = this.userLoginService.name;
        userDetail.email = this.userLoginService.email;

        this.appGlobal.userDetails = userDetail;
        resolve(userDetail);
      }
    });
  }
}
