import type { OnInit } from '@angular/core';
import { Component, ChangeDetectorRef } from '@angular/core';
import type { UserDetailsI, UserPhotoI } from '@esign/common';
import { AppGlobalService, UserLoginService} from '@esign/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {
  constructor(
    public appGlobal: AppGlobalService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private userLoginService: UserLoginService
  ) {}

  ngOnInit() {
    this.appGlobal.userDetailsChanged.subscribe((newVal: UserDetailsI) => {
      this.changeDetectorRef.detectChanges();
    });
    this.appGlobal.userPhotoChanged.subscribe((newVal: UserPhotoI) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  public logout(): void {
    this.userLoginService.logout();
  }

  getImagePath() {
     return this.appGlobal.imagePath;
  }
}
