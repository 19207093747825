<page-header></page-header>
<div class="matero-row" fxLayout="row wrap">
  <div *ngFor="let stat of stats" class="matero-col" fxFlex="100" fxFlex.gt-sm="20" fxFlex.gt-xs="50">
    <mat-card [ngClass]="['text-white', stat.color]">
      <mat-card-title class="f-s-12 f-w-100">
        <h4 class="stats-title">{{ stat.title }}</h4>
      </mat-card-title>
      <mat-card-content>
        <h2>{{ stat.value }}</h2>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<br />
<div *ngIf="isActiveInnerCertificate && isAccessInternalCertificate">
  <mat-card>
    <mat-card-content class="active-cert">
      <div>
         <div *ngIf="isActiveCert">
          <div class="cert-title">
            <span>{{ 'certificate.details.dashboard.title' | translate }}</span>
            <hr />
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.not-before' | translate }}: </label><span
              class="field-value">{{ certificateDetails.notBefore }}</span>
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.not-after' | translate }}: </label><span
              class="field-value">{{ certificateDetails.notAfter }}</span>
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.subject-name' | translate }}: </label><span
              class="field-value">{{ certificateDetails.subjectName }}</span>
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.issuer-subject-name' | translate }}: </label><span
              class="field-value">{{ certificateDetails.issuerSubjectName }}</span>
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.serial-number' | translate }}: </label><span
              class="field-value">{{ certificateDetails.serialNumber }}</span>
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.signature-algorithm' | translate }}: </label><span
              class="field-value">{{ certificateDetails.signatureAlgorithm }}</span>
          </div>

          <div class="row-details">
            <label class="field-header">{{ 'certificate.details.thumbprint' | translate }}: </label><span
              class="field-value">{{ certificateDetails.thumbprint }}</span>
          </div>
        </div>

        <div *ngIf="isActiveCert === false" class="not-active-div">
          <label class="not-active"> {{ 'certificate.details.status.not-active' | translate }} </label>
          <hr />

          <label class="not-active-message"> {{ 'certificate.details.status.not-active.message' | translate }} </label>

          <div class="form-buttons">
            <div>
              <button (click)="createCertificate()" class="m-t-8 btn-form" color="primary" mat-raised-button>
                {{ 'certificate.details.dashboard.create.btn' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>
