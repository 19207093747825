export interface UploadPhotoI {
    photo: string;
    signType: string;
  }
  
  export class UploadPhoto implements UploadPhotoI {
    photo: string;
    signType: string;
  
    constructor(
        photo,
        signType: string,
    ) {
      this.photo = photo;
        this.signType = signType;
    }
  }