import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MatSnackBar } from '@esign/material';
import { OtpBarcode } from '../../../../models/otp-barcode';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UserOtpService } from '../../../../services/user-otp/user-otp.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-esign-user-otp-add',
  templateUrl: './add-otp.component.html',
  styleUrls: ['./add-otp.component.css'],
})
export class AddOtpComponent implements OnInit {
  otpBarcode: OtpBarcode;
  barcode;

  barcodeFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddOtpComponent>,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userOtpService: UserOtpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.otpBarcode = data.otpBarcode;
    this.barcode = 'data:image/png;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(this.otpBarcode.totpSecretQrCode) as any)
      .changingThisBreaksApplicationSecurity;
  }

  ngOnInit(): void {
    this.barcodeFormGroup = this.formBuilder.group({
      otpCode: new FormControl('', {
        validators: [Validators.required],
      }),
      deviceName: new FormControl('', {
        validators: [Validators.required, Validators.minLength(2)],
      }),
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  saveOtp() {
    if (this.barcodeFormGroup.valid) {
      this.userOtpService.saveOtp(
        this.barcodeFormGroup.value.otpCode,
        this.barcodeFormGroup.value.deviceName,
        this.otpBarcode.totpSecret
      ).subscribe(
        (res: boolean) => {
          if(res){
            this.translate.get('profile.otp.credentials.save-otp.success').subscribe((text: string) => {
              this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-success'], });
            });
            this.dialogRef.close();
          }else{
            this.barcodeFormGroup.controls['otpCode'].setErrors({
              otpInvalid: true,
            });
          }
        },
        (err: HttpErrorResponse) => {
          this.translate.get('profile.otp.credentials.save-otp.error').subscribe((text: string) => {
            this.snackBar.open(text, '', { duration: 4000, panelClass: ['snack-error'], });
          });
        }
      );
    } else {
      this.barcodeFormGroup.markAllAsTouched();
    }
  }
}
