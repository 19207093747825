<button [mat-menu-trigger-for]="themeMenu" mat-icon-button matTooltip="Select a theme!" tabindex="-1">
  <mat-icon>format_color_fill</mat-icon>
</button>

<!-- TODO: replace use of `mat-menu` here with a custom overlay -->
<mat-menu #themeMenu="matMenu" class="esign-theme-picker-menu" x-position="before">
  <mat-grid-list cols="2">
    <mat-grid-tile *ngFor="let theme of themes">
      <div (click)="installTheme(theme.name)" mat-menu-item>
        <div class="esign-theme-picker-swatch">
          <mat-icon *ngIf="currentTheme === theme" class="esign-theme-chosen-icon">check_circle</mat-icon>
          <div [style.background]="theme.primary" class="esign-theme-picker-primary"></div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-menu>
