<div [dir]="options.dir"
     [ngClass]="{'matero-sidenav-collapsed': options.sidenavCollapsed && options.navPos!=='top',
                 'matero-navbar-side': options.navPos==='side',
                 'matero-navbar-top': options.navPos==='top',
                 'matero-header-above': options.headerPos==='above',
                 'matero-header-fixed': options.headerPos==='fixed',
                 'matero-header-primary': options.theme==='light'}"
     class="matero-container-wrap">

  <ng-progress ngProgressRouter />

  <!-- Header Above -->
  <app-header #header (toggleSidenav)="sidenav.toggle()"
              (toggleSidenavNotice)="sidenavNotice.toggle()"
              *ngIf="options.showHeader && options.headerPos==='above'"
              [showBranding]="true"
              class="matero-header">
  </app-header>

  <mat-sidenav-container autoFocus autosize class="matero-container">
    <mat-sidenav #sidenav (closedStart)="sidenavCloseStart()"
                 (openedChange)="sidenavOpenedChange($event)"
                 [mode]="isOver ? 'over' : 'side'"
                 [opened]="options.navPos==='side' && options.sidenavOpened && !isOver"
                 class="matero-sidenav">
      <app-sidebar (toggleCollapsed)="toggleCollapsed()"
                   [showHeader]="options.headerPos!=='above'"
                   [showToggle]="!isOver"
                   [showUser]="options.showUserPanel"
                   [toggleChecked]="options.sidenavCollapsed">
      </app-sidebar>
    </mat-sidenav>

    <mat-sidenav #sidenavNotice mode="over" position="end">
      <app-sidebar-notice></app-sidebar-notice>
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content-wrap">
      <app-header #header (toggleSidenav)="sidenav.toggle()"
                  (toggleSidenavNotice)="sidenavNotice.toggle()"
                  *ngIf="options.showHeader && options.headerPos!=='above'"
                  [showBranding]="options.navPos==='top'"
                  [showToggle]="!options.sidenavCollapsed && options.navPos!=='top'"
                  class="matero-header">
      </app-header>

      <app-topmenu *ngIf="options.navPos==='top'"></app-topmenu>

      <div class="matero-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
