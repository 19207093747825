<div class="matero-topmenu scrollbar-thin">
  <div class="matero-topmenu-inner">
    <ng-container *ngFor="let menuItem of menus | async">

      <a *ngIf="menuItem.type === 'link'" [routerLink]="buildRoute([menuItem.route])" mat-button>
        <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
        <span class="menu-name">{{menuItem.name | translate}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
      </a>
      <a *ngIf="menuItem.type === 'extLink'" [href]="menuItem.route" mat-button>
        <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
        <span class="menu-name">{{menuItem.name | translate}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
        <mat-icon>launch</mat-icon>
      </a>
      <a *ngIf="menuItem.type === 'extTabLink'" [href]="menuItem.route" mat-button target="_blank">
        <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
        <span class="menu-name">{{menuItem.name | translate}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
        <mat-icon>launch</mat-icon>
      </a>
      <button *ngIf="menuItem.type === 'sub'" [matMenuTriggerFor]="menulevel1" mat-button>
        <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
        <span class="menu-name">{{menuItem.name | translate}}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{menuItem.label.color}}">
          {{menuItem.label.value}}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{menuItem.badge.color}}">
          {{menuItem.badge.value}}
        </span>
        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
      </button>

      <!-- level 1 -->
      <mat-menu #menulevel1="matMenu">
        <ng-container *ngFor="let childLvl1 of menuItem.children">

          <a *ngIf="childLvl1.type === 'link'" [routerLink]="buildRoute([menuItem.route, childLvl1.route])"
             mat-menu-item>
            {{childLvl1.name | translate}}
          </a>
          <a *ngIf="childLvl1.type === 'extLink'" [href]="childLvl1.route"
             mat-menu-item>
            <span class="menu-name">{{childLvl1.name | translate}}</span>
            <mat-icon>launch</mat-icon>
          </a>
          <a *ngIf="childLvl1.type === 'extTabLink'" [href]="childLvl1.route" mat-menu-item
             target="_blank">
            <span class="menu-name">{{childLvl1.name | translate}}</span>
            <mat-icon>launch</mat-icon>
          </a>
          <button *ngIf="childLvl1.type === 'sub'" [matMenuTriggerFor]="menulevel2" mat-menu-item>
            {{childLvl1.name | translate}}
          </button>

          <!-- level 2 -->
          <mat-menu #menulevel2="matMenu">
            <ng-container *ngFor="let childLvl2 of childLvl1.children">

              <a *ngIf="childLvl2.type === 'link'"
                 [routerLink]="buildRoute([menuItem.route, childLvl1.route, childLvl2.route])"
                 mat-menu-item>
                {{childLvl2.name | translate}}
              </a>
              <a *ngIf="childLvl2.type === 'extLink'" [href]="childLvl2.route"
                 mat-menu-item>
                <span class="menu-name">{{childLvl2.name | translate}}</span>
                <mat-icon>launch</mat-icon>
              </a>
              <a *ngIf="childLvl2.type === 'extTabLink'" [href]="childLvl2.route" mat-menu-item
                 target="_blank">
                <span class="menu-name">{{childLvl2.name | translate}}</span>
                <mat-icon>launch</mat-icon>
              </a>
            </ng-container>
          </mat-menu>

        </ng-container>
      </mat-menu>

    </ng-container>
  </div>
</div>
