<button [matMenuTriggerFor]="menu" class="matero-toolbar-button matero-avatar-button" href="javascript:void(0)"
        mat-button>
  <img [src]="getImagePath()" alt="avatar" class="matero-avatar" width="32"/>
  <span class="matero-username" fxHide.lt-sm>{{ this.appGlobal.userDisplayName }}</span>
</button>

<mat-menu #menu="matMenu">
  <a mat-menu-item routerLink="/ui/profile">
    <mat-icon>account_circle</mat-icon>
    <span>{{ 'menu.profile' | translate }}</span>
  </a>
  <!-- <a mat-menu-item routerLink="/ui/profile/settings">
    <mat-icon>settings</mat-icon>
    <span>{{ 'menu.settings' | translate }}</span>
  </a> -->
  <a (click)="logout()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'menu.logout' | translate }}</span>
  </a>
</mat-menu>
