import { Component } from '@angular/core';
import { UserLoginService } from '@esign/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-anonymous',
  template: `
    <button
      mat-button
      class="matero-toolbar-button matero-avatar-button"
      href="javascript:void(0)"
      [matMenuTriggerFor]="menu"
    >
      <!-- <mat-icon class="icon-32">perm_identity</mat-icon> -->
      <span class="matero-username" fxHide.lt-sm>{{
        'guest.user' | translate
      }}</span>
    </button>

    <mat-menu #menu="matMenu">
      <a (click)="login()" mat-menu-item>
        <mat-icon>launch</mat-icon>
        <span>{{ 'guest.login-keycloak' | translate }}</span>
      </a>
    </mat-menu>
  `,
})
export class AnonymousComponent {
  constructor(
    private translate: TranslateService,
    private userLoginService: UserLoginService
  ) {}

  login() {
    this.userLoginService.login();
  }
}
