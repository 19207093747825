import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SessionsRoutingModule } from './sessions-routing.module';

import { Error403Component } from './403.component';
import { Error404Component } from './404.component';
import { Error500Component } from './500.component';
import { Error401Component } from './401.component';
import { Error400Component } from './400.component';
import { TranslateModule } from '@ngx-translate/core';
import { SignboxErrorComponent } from './signbox-error.component';

@NgModule({
  declarations: [
    Error400Component,
    Error401Component,
    Error404Component,
    Error403Component,
    Error500Component,
    SignboxErrorComponent
  ],
  imports: [
    SharedModule,
    SessionsRoutingModule,
    TranslateModule
  ]
})
export class SessionsModule {}
