import type { OnInit } from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UserData } from '../../../models/user-data';
import { PageTitleService } from '@esign/common';
import { MatSnackBar } from '@esign/material';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Component({
  selector: 'lib-esign-common-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileDataComponent implements OnInit {
  userData: UserData;

  constructor(
    public translate: TranslateService,
    private userProfileService: UserProfileService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private pageTitleService: PageTitleService,
    private snackBar: MatSnackBar,
    private oauthStorage: OAuthStorage
  ) {
    this.userData = new UserData();
    this.pageTitleService.title = this.setPageTitle();
  }

  ngOnInit(): void {
    this.initData();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.pageTitleService.title = this.setPageTitle();
    });
  }

  initData() {
    let claims = JSON.parse(this.oauthStorage.getItem('id_token_claims_obj'));
    let userData = new UserData();
    userData.firstName = claims.given_name;
    userData.lastName = claims.family_name;
    userData.displayName = claims.preferred_username;
    userData.email = claims.email;
    userData.usosId=claims.usos_id;
    userData.mobileNumber=claims.phone_number;
    userData.lastModifiedDate=claims.updated_at;
    userData.creationDate=claims.created_at;
    this.userData.setValue(userData);
    this.changeDetectorRef.detectChanges();

    /* this.userProfileService.getUserData().subscribe(
      (res: UserDataI) => {
        this.userData.setValue(res);
        this.changeDetectorRef.detectChanges();
      },
      () => {
        let errorMessage;
        this.translate
          .get('profile.data.get.error')
          .subscribe((text: string) => (errorMessage = text));

        this.snackBar.open(errorMessage, '', {
          duration: 4000,
          panelClass: ['snack-error'],
        });
      }
    ); */
  }

  setPageTitle(): string {
    let title = '';
    this.translate.get('page-title.profile').subscribe((text: string) => (title = text));
    return title;
  }

  /* forwardingToUpdate() {
    this.router.navigateByUrl('/profile/data/update');
  } */
}
