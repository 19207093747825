<page-header [showBreadCrumb]="false" [title]=pageTitle class="p-t-32 p-b-48 m-b--24">
</page-header>

<div class="matero-row" fxLayout="row wrap">
  <div class="matero-col" fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="33.33" fxFlex.gt-xs="100">
    <mat-card>
      <div class="text-center">
        <img [src]="getImagePath()" alt="" class="m-b-8 r-full img-avatar" width="80">
        <h2 class="m-t-0 m-b-8 f-w-400">{{ this.appGlobal.userDisplayName }}</h2>
        <p class="m-t-0 m-b-8">{{ this.appGlobal.userEMail }}</p>

      </div>
      <!-- <mat-nav-list class="m-x--16 m-b--16 m-t-16"> -->
      <mat-nav-list>
        <mat-divider inset></mat-divider>
        <a mat-list-item routerLink="/ui/profile">
          <mat-icon class="icon-18 m-r-8">settings</mat-icon>
          {{"profile.menu.profile" | translate}}
        </a>
        <div *ngIf="isAccessUserProfile">
          <mat-divider inset></mat-divider>
          <a mat-list-item routerLink="/ui/profile/avatar">
            <mat-icon class="icon-18 m-r-8">settings</mat-icon>
            {{"profile.menu.change-avatar" | translate}}
          </a>
        </div>
        <div *ngIf="isAccessOtp">
          <mat-divider inset></mat-divider>
          <a mat-list-item routerLink="/ui/profile/otp">
            <mat-icon class="icon-18 m-r-8">settings</mat-icon>
            {{"profile.menu.set-otp" | translate}}
          </a>
        </div>
        <div *ngIf="isAccessVisualization">
          <mat-divider inset></mat-divider>
          <a mat-list-item routerLink="/ui/profile/visualization">
            <mat-icon class="icon-18 m-r-8">settings</mat-icon>
            {{"profile.menu.visualization" | translate}}
          </a>
        </div>
      </mat-nav-list>
    </mat-card>
  </div>

  <div class="matero-col" fxFlex="100" fxFlex.gt-md="75" fxFlex.gt-sm="66.67" fxFlex.gt-xs="100">
    <router-outlet></router-outlet>
  </div>

</div>