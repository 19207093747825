import { Injectable, EventEmitter } from '@angular/core';
import {
  EMPTY_USER_DETAILS,
  UserDetailsI,
  UserDetails,
} from '../interfaces/user-details.interface';
import {
  DEFAULT_USER_PHOTO,
  UserPhotoI,
  UserPhoto,
} from '../interfaces/user-photo.interface';

@Injectable({
  providedIn: 'root',
})
export class AppGlobalService {
  private userDetailsV: UserDetails = new UserDetails();
  private userPhotoV: UserPhoto = new UserPhoto();

  private userPhotoChangedV: EventEmitter<UserPhotoI> = new EventEmitter();
  private userDetailsChangedV: EventEmitter<UserDetails> = new EventEmitter();

  constructor() {
    this.userDetailsV.setValue(EMPTY_USER_DETAILS);
    this.userPhotoV.setValue(DEFAULT_USER_PHOTO);
  }

  public get userPhotoChanged(): EventEmitter<UserPhotoI> {
    return this.userPhotoChangedV;
  }

  public get userDetailsChanged(): EventEmitter<UserDetailsI> {
    return this.userDetailsChangedV;
  }

  public get userDetails(): UserDetailsI {
    return this.userDetailsV;
  }

  public set userDetails(value: UserDetailsI) {
    this.userDetailsV.setValue(value);
    this.userDetailsChanged.emit(this.userDetailsV);
  }

  public get userName(): string {
    return this.userDetailsV.name;
  }

  public get userEMail(): string {
    return this.userDetailsV.email;
  }

  public get userDisplayName(): string {
    return this.userDetailsV.userDisplayName();
  }

  public get userPhoto(): UserPhotoI {
    return this.userPhotoV;
  }

  public set userPhoto(value: UserPhotoI) {
    this.userPhotoV.setValue(value);
    this.userPhotoChanged.emit(this.userPhotoV);
  }

  public get isDownloadedPhoto(): boolean {
    return this.userPhotoV.isDownloadPhoto;
  }

  public set isDownloadedPhoto(value: boolean) {
    this.userPhotoV.isDownloadPhoto = value;
  }

  public set photo(value: any) {
    this.userPhotoV.photo = value;
    this.userPhotoV.isDownloadPhoto = true;
    this.userPhotoChanged.emit(this.userPhotoV);
  }

  public get photo(): any {
    return this.userPhotoV.photo;
  }

  public get imagePath(): string {
    return this.userPhotoV.imagePath();
  }
}
