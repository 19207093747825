export interface UserPhotoI {
  photo: any;
  defaultPhoto: any;
  isDownloadPhoto: boolean;
}

export class UserPhoto implements UserPhotoI {
  photo: any = null;
  defaultPhoto: any = null;
  isDownloadPhoto: boolean = null;

  setValue(value: UserPhotoI) {
    this.photo = value.photo;
    this.defaultPhoto = value.defaultPhoto;
    this.isDownloadPhoto = value.isDownloadPhoto;
  }

  getValue(): UserPhotoI {
    return {
      photo: this.photo,
      defaultPhoto: this.defaultPhoto,
      isDownloadPhoto: this.isDownloadPhoto
    }
  }

  imagePath(): string {
    if (this.photo === null || this.photo === undefined) {
      return this.defaultPhoto;
    } else {
      return this.photo;
    }
  }

}

export const DEFAULT_USER_PHOTO: UserPhotoI = {
  photo: null,
  defaultPhoto: 'assets/images/avatar.png',
  isDownloadPhoto: false
};

export const DEFAULT_USER_PHOTO_DOWNLOADED: UserPhotoI = {
  photo: 'assets/images/avatar.png',
  defaultPhoto: 'assets/images/avatar.png',
  isDownloadPhoto: true
};
