export interface CredentialI {
  credential: UserCredentials;
}

export class UserCredentials {
  createdDate: Date;
  id: String;
  type: String;
  userLabel: String;
  credentialData: CredentialData;

  constructor(createdDate: Date, credentialData: CredentialData, id: String, type: String, userLabel: String) {
    this.id = id;
    this.createdDate = createdDate;
    this.credentialData = credentialData;
    this.type = type;
    this.userLabel = userLabel;
  }
}

export class CredentialData {
  subtype: String;
  digits: String;
  counter: String;
  period: String;
  algorithm: String;

  constructor(subtype: String,
    digits: String,
    counter: String,
    period: String,
    algorithm: String) {
    this.subtype = subtype;
    this.digits = digits;
    this.counter = counter;
    this.period = period;
    this.algorithm = algorithm;
  }
}

export class ActiveCredential {
  type: string;
  value: String;
  otpId: String;

  constructor(type: string, value: String, otpId: String) {
    this.type = type;
    this.value = value;
    this.otpId = otpId;
  }
}
