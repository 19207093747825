import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from '@esign/common';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit {

  ngOnInit(): void {
    this.userLoginService.redirectOnCallback();

    setTimeout(() => {
      if (this.router.url.includes("callback")) {
        this.router.navigateByUrl('/ui/dashboard');
      }
    }, 3000);
  }

  constructor(private userLoginService: UserLoginService,
    private router: Router) { }
}
