<nav aria-label="breadcrumb">
  <ol class="matero-breadcrumb">
    <li *ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst;"
        class="matero-breadcrumb-item">
      <a *ngIf="isFirst" class="link" href="/ui">{{navlink}}</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron">chevron_right</mat-icon>
        <span class="link">{{navlink | translate}}</span>
      </ng-container>
    </li>
  </ol>
</nav>
