export interface SelectedItemI {
    id: string;
    title: string;
    description: string;
    isSigned: boolean;
}

export class SelectedItem implements SelectedItemI {
    id: string;
    title: string;
    description: string;
    isSigned: boolean;

    constructor(
        id: string,
        title: string,
        description: string,
        isSigned: boolean
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.isSigned = isSigned;
    }
}
