<mat-toolbar class="matero-toolbar">

  <app-branding *ngIf="showBranding"></app-branding>

  <span fxFlex></span>

  <button (click)="toggleFullscreen()" class="matero-toolbar-button" fxHide.lt-sm
          mat-icon-button>
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-translate></app-translate>

  <app-anonymous></app-anonymous>

</mat-toolbar>
