export interface UserDataI {
  loginName: String;

  firstName: String;
  lastName: String;
  email: String;
  mobileNumber: String;
  displayName: String;

  providerName: String;
  roleName: String;
  creationDate: String;
  lastModifiedDate: String;
  usosId: Number;
}

export class UserData implements UserDataI {
  loginName: String;

  firstName: String;
  lastName: String;
  email: String;
  mobileNumber: String;
  displayName: String;

  providerName: String;
  roleName: String;
  creationDate: String;
  lastModifiedDate: String;
  usosId: Number;

  setValue(value: UserDataI) {
    this.loginName = value.loginName;

    this.firstName = value.firstName;
    this.lastName = value.lastName;
    this.email = value.email;
    this.mobileNumber = value.mobileNumber;
    this.displayName = value.displayName;

    this.providerName = value.providerName;
    this.roleName = value.roleName;
    this.creationDate = value.creationDate;
    this.lastModifiedDate = value.lastModifiedDate;
    this.usosId = value.usosId;
  }
}
