import '@angular/compiler';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AppGlobalService,
  AppStartupService,
  UserAuthInterceptor,
  EsignCommonModule,
  PageTitleService,
  SharedModule,
  UserLoginService,
  UserDetailsService,
  UserPhotoService,
  ForcedLoginGuard,
  LanguageInterceptor
} from '@esign/common';
import { MaterialModule, MatSnackBarModule } from '@esign/material';
import {
  CoreModule,
  SharedModule as MateroSharedModule,
  StartupService,
  ThemeModule,
  DefaultInterceptor,
} from '@ng-matero/common';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { RoutesAppModule } from './routes/routes-app.module';
import { RoutesRoutingModule } from './routes/routes-routing.module';
import { OAuthService } from 'angular-oauth2-oidc';

import { TranslateLangService } from '@ng-matero/common';
export function TranslateLangServiceFactory(translateLangService: TranslateLangService) {
  return () => translateLangService.load();
}
export function StartupServiceFactory(startupService: StartupService) {
  return async () => await startupService.load();
}
export function UserDetailsServiceFactory(userDetailsService: UserDetailsService) {
  return () => userDetailsService.load();
}
export function UserPhotoServiceFactory(userPhotoService: UserPhotoService) {
  return () => userPhotoService.load();
}

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    CoreModule,
    MateroSharedModule,
    ThemeModule,
    RoutesAppModule,
    RoutesRoutingModule,
    FormlyModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    EsignCommonModule,
    MatSnackBarModule,
  ],
  providers: [
    UserLoginService,
    ForcedLoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    AppStartupService,
    AppGlobalService,
    PageTitleService,
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    UserDetailsService,
    {
      provide: APP_INITIALIZER,
      useFactory: UserDetailsServiceFactory,
      deps: [UserDetailsService],
      multi: true,
    },
    UserPhotoService,
    {
      provide: APP_INITIALIZER,
      useFactory: UserPhotoServiceFactory,
      deps: [UserPhotoService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateLangServiceFactory,
      deps: [TranslateLangService],
      multi: true,
    },
    TranslateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
