import { Injectable } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfilePhotoService {
  constructor(private http: HttpClient) {}

  uploadPhoto(data) {
    return this.http.post('/auth/realms/esign/user-avatar/esign-backend/avatar/upload', data).pipe(catchError(this.errorHandler));
  }

  deletePhoto() {
    return this.http.delete('/auth/realms/esign/user-avatar/esign-backend/avatar/delete').pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
