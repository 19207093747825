import { StringLiteralLike } from "typescript";

export interface OtpBarcodeI {
  enabled: boolean;
  policy: Policy;
  manualUrl: String;
  qrUrl: String;
  totpSecret: String;
  totpSecretEncoded: String;
  totpSecretQrCode: string;
  otpCredentials: Array<String>;
  supportedApplications: Array<String>;
}

export class OtpBarcode implements OtpBarcodeI {
  enabled: boolean;
  policy: Policy;
  manualUrl: String;
  qrUrl: String;
  totpSecret: String;
  totpSecretEncoded: String;
  totpSecretQrCode: string;
  otpCredentials: Array<String> = [];
  supportedApplications: Array<String> = [];

  constructor(enabled: boolean,
    policy: Policy,
    manualUrl: String,
    qrUrl: String,
    totpSecret: String,
    totpSecretEncoded: String,
    totpSecretQrCode: string,
    otpCredentials: Array<String> = [],
    supportedApplications: Array<String> = []) {
    this.enabled = enabled;
    this.policy = policy;
    this.manualUrl = manualUrl;
    this.qrUrl = qrUrl;
    this.totpSecret = totpSecret;
    this.totpSecretEncoded = totpSecretEncoded;
    this.totpSecretQrCode = totpSecretQrCode;
    this.otpCredentials = otpCredentials;
    this.supportedApplications = supportedApplications;
  }
}

export class Policy {
  algorithm: String;
  algorithmKey: String;
  digits: number;
  initialCounter: number;
  lookAheadWindow: number;
  period: number;
  type: String;
  codeReusable: boolean;

  constructor(algorithm: String,
    algorithmKey: String,
    digits: number,
    initialCounter: number,
    lookAheadWindow: number,
    period: number,
    type: String,
    codeReusable: boolean,) {
    this.algorithm = algorithm;
    this.algorithmKey = algorithmKey;
    this.digits = digits;
    this.initialCounter = initialCounter;
    this.lookAheadWindow = lookAheadWindow;
    this.period = period;
    this.type = type;
    this.codeReusable = codeReusable;
  }
}

export class AddOtpModel {
  otpCode: String;
  deviceName: String;
  totpSecret: String;

  constructor(otpCode: String,
    deviceName: String,
    totpSecret: String) {
    this.otpCode = otpCode;
    this.deviceName = deviceName;
    this.totpSecret = totpSecret;
  }
}
