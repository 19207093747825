import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserLoginService } from '../login/user-login.service';

@Injectable()
export class ForcedLoginGuard implements CanActivate {
  constructor(
    private userLoginService: UserLoginService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.userLoginService.oauthService.hasValidAccessToken() &&
      this.userLoginService.oauthService.hasValidIdToken()
    ) {
      return true;
    }

    this.userLoginService.login(true);
    return false;
  }
}
