@if (showHeader) {
  <div class="matero-sidebar-header">
    <mat-toolbar>
      <!-- <app-branding [showName]="!toggleChecked" /> -->
      <app-branding-sidebar></app-branding-sidebar>

      <span class="flex-fill"></span>

      @if (showToggle) {
        <mat-slide-toggle hideIcon [checked]="toggleChecked" (change)="toggleCollapsed.emit()" />
      } @else {
        <button mat-icon-button (click)="closeSidenav.emit()">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-toolbar>
  </div>
}

<div class="matero-sidebar-main">
  @if (showUser) {
    <app-user-panel />
  }
  <app-sidemenu [ripple]="showToggle" />
</div>