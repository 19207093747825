import { Component, Input, OnInit } from '@angular/core';
import { Menu, MenuService } from '../../core/bootstrap/menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;
  menus: Observable<Menu[]>;;

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
   this.menus = this.menuService.getAll();
  }

  // Delete empty values and rebuild route
  buildRoute(routes: string[]) {
    let route = '';
    routes.forEach(item => {
      if (item && item.trim()) {
        route += '/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return route;
  }
}
