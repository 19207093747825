import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageInterceptor implements HttpInterceptor {

  constructor(
    private translate: TranslateService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request.clone({
      setHeaders: {
        'Accept-Language': this.getCurrentLang(),
      }
  }));
  }

  getCurrentLang(): string {
    if (this.translate.store.currentLang === 'pl-PL') {
      return 'pl';
    } else {
      return 'en';
    }
  }
}
