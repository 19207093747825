<div class="matero-row details-content">
  <mat-card>
    <div class="details-header">
      {{ 'certificate.details.title' | translate }}
    </div>
    <hr/>

    <div class="row-details">
      <label class="field-header">{{ 'certificate.details.identity-id' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.identityId }}</span>
    </div>

    <div class="row-details">
      <label class="field-header">{{ 'certificate.details.not-before' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.notBefore }}</span>
    </div>

    <div class="row-details">
      <label class="field-header">{{ 'certificate.details.not-after' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.notAfter }}</span>
    </div>

    <div class="row-details">
      <label class="field-header">{{ 'certificate.details.subject-name' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.subjectName }}</span>
    </div>

    <div class="row-details">
      <label class="field-header"
      >{{ 'certificate.details.issuer-subject-name' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.issuerSubjectName }}</span>
    </div>

    <div class="row-details">
      <label class="field-header">{{ 'certificate.details.serial-number' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.serialNumber }}</span>
    </div>

    <div class="row-details">
      <label class="field-header"
      >{{ 'certificate.details.signature-algorithm' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.signatureAlgorithm }}</span>
    </div>

    <div class="row-details">
      <label class="field-header">{{ 'certificate.details.thumbprint' | translate }}: </label
      ><span class="field-value">{{ certificateDetails.thumbprint }}</span>
    </div>
  </mat-card>

  <div class="details-buttons">
    <button (click)="back()" class="m-t-8" class="btn-form" color="primary" mat-raised-button>
      {{ 'certificate.details.back-btn' | translate }}
    </button>
  </div>
</div>
