import { Component } from '@angular/core';

@Component({
  selector: 'app-branding-sidebar',
  template: `
    <a class="matero-branding" href="ui/">
      <img src="./assets/images/logo-sidebar.svg" class="matero-branding-logo-expanded" alt="logo" />
      <span class="matero-branding-name">{{"systemName" | translate}}</span>
    </a>
  `,
  styles: [`
    .matero-branding-logo-expanded {
      height: 50px;
      width: unset;
    }`
  ],
})
export class BrandingSidebarComponent {}
