import { Injectable } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OtpBarcodeI, AddOtpModel } from '../../models/otp-barcode';
import { ActiveCredential } from '../../models/user-credentials';

@Injectable({
  providedIn: 'root',
})
export class UserOtpService {
  constructor(private http: HttpClient) { }

  getUserOtpCredentials() {
    return this.http
      .get('/auth/realms/esign/sign-auth/credentials', {
        responseType: 'text'
      })
      .pipe(catchError(this.errorHandler));
  }

  deleteUserOtp(id: string) {
    return this.http
      .delete('/auth/realms/esign/sign-auth/credentials/' + id)
      .pipe(catchError(this.errorHandler));
  }

  addOtp(): Observable<OtpBarcodeI> {
    return this.http
      .get<OtpBarcodeI>('/auth/realms/esign/sign-auth/credentials/add')
      .pipe(catchError(this.errorHandler));
  }

  saveOtp(otpCode: string, deviceName: string, totpSecret: String): Observable<boolean> {
    let otpModel = new AddOtpModel(otpCode, deviceName, totpSecret);
    return this.http
      .post<boolean>('/auth/realms/esign/sign-auth/credentials/save', otpModel, {
        headers: {
          'Content-Type': `application/json`,
        },
      })
      .pipe(catchError(this.errorHandler));
  }

  enableSmsOtp(): Observable<boolean> {
    return this.http
      .get<boolean>('/auth/realms/esign/sign-auth/sms/active')
      .pipe(catchError(this.errorHandler));
  }

  getPhoneNumber(): Observable<string> {
    return this.http
      .get<string>('/auth/realms/esign/sign-auth/sms/phone')
      .pipe(catchError(this.errorHandler));
  }

  enableEmailOtp(): Observable<boolean> {
    return this.http
      .get<boolean>('/auth/realms/esign/sign-auth/email/active')
      .pipe(catchError(this.errorHandler));
  }

  getPreferredDevice(): Observable<ActiveCredential> {
    return this.http
      .get<ActiveCredential>('/auth/realms/esign/sign-auth/otp/preferred')
      .pipe(catchError(this.errorHandler));
  }

  addPreferredOtp(type: string, value: String, otpId: String): Observable<String> {
    let addPreferredOtp = new ActiveCredential(type, value, otpId);
    return this.http
      .post<String>('/auth/realms/esign/sign-auth/otp/preferred/add', addPreferredOtp, {
        headers: {
          'Content-Type': `application/json`,
        },
      })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
