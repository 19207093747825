import { NgModule } from '@angular/core';
import { ForcedLoginGuard } from '@esign/common';
import { RouterModule, Routes } from '@angular/router';
import {
  UserLayoutComponent,
  DashboardComponent,
  GuestLayoutComponent,
  RedirectComponent
} from '@ng-matero/common';
import { HomepageComponent } from './homepage';
import { InnerSignGuard } from './guard/inner-sign-guard';
import { SealInnerSignGuard } from './guard/seal-inner-sign-guard';
import { RoleGuard } from './guard/role-guard';
import { InternalCertificateGuard } from './guard/internal-certificate-guard';

const routes: Routes = [
  {
    path: '',
    component: GuestLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'callback', component: RedirectComponent },
      {
        path: 'home',
        component: HomepageComponent,
        data: { title: 'Guest', titleI18n: 'guest' }
      },
      {
        path: 'sessions',
        loadChildren: () =>
          import('./sessions/sessions.app.module').then(
            (m) => m.SessionsAppModule
          ),
        data: { title: 'Sessions', titleI18n: 'Sessions' },
      },
    ],
  },
  {
    path: 'ui',
    component: UserLayoutComponent,
    canActivate: [ForcedLoginGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        // redirectTo: 'documents/list',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'profile', titleI18n: 'Profile', role: 'user_profile' },
        canActivate: [RoleGuard]
      },
      {
        path: 'documents/list/sign',
        loadChildren: () =>
          import('./lazy/documents/documents-signing/documents-signing-lazy.module').then(
            m => m.DocumentsSigningLazyModule
          ),
        data: { title: 'signing', titleI18n: 'signing' },
      },
      {
        path: 'documents/list',
        loadChildren: () =>
          import('./lazy/documents/documents-list/documents-list-lazy.module').then(
            m => m.DocumentsListLazyModule
          ),
        data: { title: 'documents', titleI18n: 'documents', role:'documents_list' },
        canActivate: [RoleGuard]
      },
      {
        path: 'certificates/list/add',
        loadChildren: () =>
          import('./lazy/certificate/certificate-token/certificate-token-lazy.module').then(
            m => m.CertificateTokenLazyModule
          ),
        data: { title: 'create certificate', titleI18n: 'create certificate', role: 'internal_certificate'},
        canActivate: [InnerSignGuard, RoleGuard]
      },
      {
        path: 'certificates/list',
        loadChildren: () =>
          import('./lazy/certificate/certificates-user-list/certificates-user-list-lazy.module').then(
            m => m.CertificatesUserListLazyModule
          ),
        data: { title: 'certificates', titleI18n: 'certificates', role: 'internal_certificate'},
        canActivate: [InnerSignGuard, RoleGuard]
      },
      {
        path: 'certificates/all-list',
        loadChildren: () =>
          import('./lazy/certificate/certificates-admin-list/certificates-admin-list-lazy.module').then(
            m => m.CertificatesAdminListLazyModule
          ),
        data: { title: 'certificates', titleI18n: 'certificates',role: 'internal_certificate_all_list' },
        canActivate: [InnerSignGuard, RoleGuard]
      },
      {
        path: 'certificates/seal',
        loadChildren: () =>
          import('./lazy/certificate/certificates-seal-list/certificates-seal-list-lazy.module').then(
            m => m.CertificatesSealListLazyModule
          ),
        data: { title: 'certificates', titleI18n: 'certificates', role: 'internal_certificate_seal_list' },
        canActivate: [SealInnerSignGuard, RoleGuard]
      },
      {
        path: 'certificates/details',
        loadChildren: () =>
          import('./lazy/certificate/details-certificate/details-certificate-lazy.module').then(
            m => m.DetailsCertificateLazyModule
          ),
        data: { title: 'certificates', titleI18n: 'certificates', role: 'internal_certificate' },
        canActivate: [InternalCertificateGuard, RoleGuard]
      },
      {
        path: 'orders/list',
        loadChildren: () =>
          import('./lazy/order/order-user/order-user.module').then(
            m => m.OrderUserLazyModule
          ),
        data: { title: 'signbox order', titleI18n: 'signbox-order', role: 'order_list' },
        canActivate: [RoleGuard]
      },
      {
        path: 'orders/all-list',
        loadChildren: () =>
          import('./lazy/order/order-admin/order-admin.module').then(
            m => m.OrderAdminLazyModule
          ),
        data: { title: 'signbox order', titleI18n: 'signbox-order', role: 'order_all_list' },
        canActivate: [RoleGuard]
      },
      {
        path: 'orders/details',
        loadChildren: () =>
          import('./lazy/order/order-details/order-details.module').then(
            m => m.OrderDetailsLazyModule
          ),
        data: { title: 'signbox order', titleI18n: 'signbox-order', role: 'order_details_own; order_details_all' },
        canActivate: [RoleGuard]
      },
      {
        path: 'logs/audit',
        loadChildren: () =>
          import('./lazy/audit/audit-user/audit-user-lazy.module').then(m => m.AuditUserLazyModule),
        data: { title: 'Event logs', titleI18n: 'Event logs' },
      },
      {
        path: 'logs/system-audit',
        loadChildren: () => import('./lazy/audit/audit-admin/audit-admin-lazy.module').then(m => m.AuditAdminLazyModule),
        canActivate: [RoleGuard],
        data: { title: 'Event logs', titleI18n: 'Event logs', role:"audit_admin_list" },
      },
      {
        path: 'settings/sign-visualization',
        loadChildren: () => import('./lazy/settings/sign-visualization/sign-visualization.module').then(m => m.SignVisualizationLazyModule),
        canActivate: [RoleGuard],
        data: { title: 'Settings', titleI18n: 'Settings', role:"settings_sign_visualization" },
      }
    ],
  },
  {
    path: '**',
    redirectTo: 'ui',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
