<div class="add-otp-dialog">
  <div class="title">
    {{ 'profile.otp.credentials.add-otp-title' | translate }}
  </div>

  <div class="step-title">
  1. {{ 'profile.otp.credentials.add-otp-step1' | translate }}
  </div>
  <div *ngFor="let app of otpBarcode.supportedApplications">
    <span class="supported-app">
      - {{ app | translate }}
    </span>
  </div>

  <div class="step-title">
  2. {{ 'profile.otp.credentials.add-otp-step2' | translate }}
  </div>

  <img class="barcode" [src]="barcode">

  <div class="step-title">
  3. {{ 'profile.otp.credentials.add-otp-step3' | translate }}
  </div>
  <form #formDirective="ngForm" [formGroup]="barcodeFormGroup" width="100%">

    <mat-form-field appearance="standard" class="form-field">
      <mat-label>{{ 'profile.otp.credentials.add-otp.form.otp-code' | translate }}</mat-label>
      <input autocomplete="new-password" formControlName="otpCode" matInput
        placeholder="{{ 'profile.otp.credentials.add-otp.form.otp-code' | translate }}" type="text" />
      <mat-error *ngIf="barcodeFormGroup.get('otpCode')?.errors?.required">
        {{ "profile.otp.credentials.add-otp.otp-required-error" | translate }}
      </mat-error>
      <mat-error *ngIf="barcodeFormGroup.get('otpCode')?.errors?.otpInvalid">
        {{ "profile.otp.credentials.add-otp.otp-validation-error" | translate }}
      </mat-error>
    </mat-form-field>
    <br>
    <mat-form-field appearance="standard" class="form-field">
      <mat-label>{{ 'profile.otp.credentials.add-otp.form.device-name' | translate }}</mat-label>
      <input autocomplete="new-password" formControlName="deviceName" matInput
        placeholder="{{ 'profile.otp.credentials.add-otp.form.device-name' | translate }}" type="text" />
      <mat-error *ngIf="barcodeFormGroup.get('deviceName')?.errors?.required">
        {{ "profile.otp.credentials.add-otp.device-name-required-error" | translate }}
      </mat-error>
      <mat-error *ngIf="barcodeFormGroup.get('deviceName')?.errors?.minLength">
        {{ "profile.otp.credentials.add-otp.device-name-length-error" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-card-actions>
      <div class="buttons-form">
        <button (click)="cancel()" class="btn-confirm" mat-raised-button mat-stroked-button color="primary"
          type="submit">
          {{ 'profile.otp.credentials.add-otp.form.cancel' | translate }}
        </button>

        <button (click)="saveOtp()" class="btn-confirm" color="primary" mat-raised-button mat-stroked-button
          type="submit">
          {{ 'profile.otp.credentials.add-otp.form.save' | translate }}
        </button>
      </div>
    </mat-card-actions>
  </form>
</div>