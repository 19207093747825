import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import type { OnInit } from '@angular/core';
import { PageTitleService, UserLoginService } from '@esign/common';
import type { CertificateDetailsI } from '@esign/certificates';
import { CertificateService, CertificateDetails } from '@esign/certificates';
import { UserStatsService } from '@esign/users';
import type { Stats } from '@esign/common';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  stats: Array<Stats> = [];
  certificateDetails: CertificateDetails;
  isActiveCert = true;
  isActiveInnerCertificate = false;
  isActiveStatistics = false;
  isAccessInternalCertificate = false;

  constructor(
    public translate: TranslateService,
    private userStatsService: UserStatsService,
    private cdr: ChangeDetectorRef,
    private pageTitleService: PageTitleService,
    private certificateService: CertificateService,
    private router: Router,
    private userLoginService: UserLoginService
  ) {
    this.certificateDetails = new CertificateDetails();
    this.pageTitleService.title = this.setPageTitle();
  }

  async ngOnInit() {
    let res = await this.hasAccesToStatistics();
    let res1 = await this.hasAccesToCertificate();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.hasAccesToStatistics();
      this.pageTitleService.title = this.setPageTitle();
    });
  }

  async hasAccesToStatistics() {
    this.userLoginService.hasRole("user_statistics")
      .then((res: boolean) => {
        this.isActiveStatistics = res;
        if(res){
          this.initStats();
        }
      });
  }

  async hasAccesToCertificate() {
    this.userLoginService.hasRole("internal_certificate")
      .then((res: boolean) => {
        this.isAccessInternalCertificate = res;
        if(res){
          this.isActiveInnerCertificateSign();
          this.initCertsDetails();
        }
      });
  }

  isActiveInnerCertificateSign() {
    this.certificateService
      .activeInnerCertificateSign()
      .subscribe((res: boolean) => {
        this.isActiveInnerCertificate = res;
        this.cdr.detectChanges();
      })
  }

  initStats() {
    if(this.isActiveStatistics){
      this.userStatsService.getStats().then((res: Array<Stats>) => {
        this.stats = res;
        this.cdr.detectChanges();
      });
    }
  }

  initCertsDetails() {
      this.certificateService.getUserCertificate().subscribe((res: CertificateDetailsI) => {
        this.certificateDetails.setValue(res);
        if (this.certificateDetails.status != 'Active') this.isActiveCert = false;
  
        this.cdr.detectChanges();
      });
  }

  setPageTitle(): string {
    let title = '';
    this.translate.get('page-title.dashboard').subscribe((text: string) => (title = text));
    return title;
  }

  createCertificate() {
    this.router.navigateByUrl('/ui/certificates/list/add');
  }
}
